export const Specialization = {
  AMERICAN_TRADITIONAL: "specializations-american_traditional",
  ABSTRACT: "specializations-abstract",
  BLACK_AND_GRAY: "specializations-black_and_gray",
  BLACKWORK: "specializations-blackwork",
  CHICANO: "specializations-chicano",
  DOTWORK: "specializations-dotwork",
  FINE_LINE: "specializations-fine_line",
  GEOMETRIC: "specializations-geometric",
  STICK_AND_POKE: "specializations-stick_and_poke",
  JAPANESE_IREZUMI: "specializations-japanese_irezumi",
  LETTERING_SCRIPT: "specializations-lettering_script",
  NEO_TRADITIONAL: "specializations-neo_traditional",
  NEW_SCHOOL: "specializations-new_school",
  ORNAMENTAL: "specializations-ornamental",
  REALISM: "specializations-realism",
  ILLUSTRATIVE: "specializations-illustrative",
  TRASH_POLKA: "specializations-trash_polka",
  TRIBAL: "specializations-tribal",
  WATERCOLOR: "specializations-watercolor",
  SURREALISM: "specializations-surrealism",
  PORTRAIT: "specializations-portrait",
  SKETCH: "specializations-sketch",
  MINIMALIST: "specializations-minimalist",
  HORROR: "specializations-horror",
  POLYNESIAN: "specializations-polynesian",
  BIOMECHANICAL: "specializations-biomechanical",
  FLORAL: "specializations-floral",
};

import React, { useMemo, useCallback } from "react";
import { Button, IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ProfilePhoto } from ".";
import { priceMapping } from "../models/Price";
import { ReactComponent as Remove } from "../assets/icons/remove.svg";
import { ReactComponent as MessageFly } from "../assets/icons/message-fly.svg";
import { ConnectionStatus } from "../models";

const preventDefault = (event) => {
  event.preventDefault();
};

export const ConnectionCard = ({
  item,
  requestId,
  artistId,
  isOwner,
  className,
  variant,
  onConnect,
  onRemoveConnection,
  onRemoveRequest,
  onConfirmRequest,
  onSendMessage,
  ...props
}) => {
  const { t } = useTranslation();

  const intersectionOptions = useMemo(
    () => ({
      root: document.querySelector("#layout-scroll"),
      rootMargin: "240px 60px", // prefetch connection card on scroll
    }),
    []
  );

  const handleConnect = useCallback(
    (e) => {
      e.stopPropagation();
      if (onConnect) {
        onConnect(item.id);
      }
    },
    [onConnect, item]
  );

  const handleSendMessage = useCallback(
    (e) => {
      e.stopPropagation();
      if (onSendMessage) {
        onSendMessage(item);
      }
    },
    [onSendMessage, item]
  );

  const handleRemoveConnection = useCallback(
    (e) => {
      e.stopPropagation();
      if (onRemoveConnection) {
        onRemoveConnection(artistId);
      }
    },
    [onRemoveConnection, artistId]
  );

  const handleRemoveRequest = useCallback(
    (e) => {
      e.stopPropagation();
      if (onRemoveRequest && requestId) {
        onRemoveRequest(requestId);
      }
    },
    [onRemoveRequest, requestId]
  );

  const handleConfirmRequest = useCallback(
    (e) => {
      e.stopPropagation();
      if (onConfirmRequest && requestId) {
        onConfirmRequest(requestId);
      }
    },
    [onConfirmRequest, requestId]
  );

  const renderActions = useMemo(() => {
    return {
      [ConnectionStatus.Potential]: (
        <div className="absolute top-3 right-3">
          <Button
            variant="contained"
            color="primary"
            onClick={handleConnect}
            size="small"
          >
            {t("edit-profile-page_connect")}
          </Button>
        </div>
      ),
      [ConnectionStatus.Connected]: (
        <div className="absolute top-3 right-3">
          <IconButton
            aria-label="Remove connection"
            onClick={handleRemoveConnection}
            onMouseDown={preventDefault}
            size="small"
          >
            <Remove />
          </IconButton>
          <IconButton
            aria-label="Send message"
            onClick={handleSendMessage}
            onMouseDown={preventDefault}
            size="small"
          >
            <MessageFly height="20.85" width="23.76" />
          </IconButton>
        </div>
      ),
      [ConnectionStatus.Pending]: null,
    };
  }, [t, handleConnect, handleRemoveConnection, handleSendMessage]);

  return (
    <article className={className} {...props}>
      <div className="relative w-full flex flex-col rounded-xl border-1/2 border-ash py-3 px-2">
        <div className="flex flex-col">
          {renderActions[variant]}
          <div className="flex flex-row w-8/12">
            <ProfilePhoto
              imgKey={item.profilePic?.key}
              size="md"
              intersectionOptions={intersectionOptions}
            />
            <div className="flex flex-col pl-3">
              <div className="font-bold">{item.fullName}</div>
              <div>{item.location}</div>
              <div className="font-bold">
                {(item.pricePoint && t(priceMapping[item.pricePoint])) ||
                  "$???"}
              </div>
            </div>
          </div>
        </div>
        {variant === ConnectionStatus.Pending && (
          <div className="flex justify-evenly m-5">
            {!isOwner && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleConfirmRequest}
                size="small"
              >
                {t("edit-profile-page_confirm")}
              </Button>
            )}
            <Button
              variant="outlined"
              color="primary"
              onClick={handleRemoveRequest}
              size="small"
            >
              {t("edit-profile-page_delete")}
            </Button>
          </div>
        )}
      </div>
    </article>
  );
};

import React from "react";
import { useAuth } from "./hooks/useAuth";
import { AuthContext } from "./hooks/useAuthContext";

export const AuthProvider = ({ children, ...authProviderProps }) => {
  const authContexProviderProps = useAuth(authProviderProps);

  return (
    <AuthContext.Provider value={authContexProviderProps}>
      {children}
    </AuthContext.Provider>
  );
};

import React from "react";
import { Toolbar, IconButton } from "@material-ui/core";
import clsx from "clsx";

export const NavigationHeader = ({
  title,
  onActionClick,
  icon,
  onAltActionClick,
  altIcon,
  decorated = true,
}) => {
  const className = clsx("w-full", {
    "border-b-4 border-black": decorated,
  });
  return (
    <Toolbar className={className}>
      {icon && (
        <IconButton className="absolute" onClick={onActionClick}>
          {icon}
        </IconButton>
      )}
      <span className="mx-auto uppercase text-2xl font-bold">{title}</span>
      {altIcon && (
        <IconButton className="absolute right-3" onClick={onAltActionClick}>
          {altIcon}
        </IconButton>
      )}
    </Toolbar>
  );
};

import React, {
  useEffect,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TextField, Button } from "@material-ui/core";
import { useErrors } from "../../../hooks";
import { FormError } from "../../../components";

export const EmailItem = forwardRef(({ onSave, email }, ref) => {
  const { t } = useTranslation();
  const { reset, control, handleSubmit, errors } = useForm();
  const { hasError, getErrorMessage } = useErrors(errors);
  const [formError, setFormError] = useState(null);

  const onSubmit = useCallback(
    async ({ email: emailToSave }) => {
      try {
        onSave(emailToSave);
      } catch (error) {
        setFormError(error);
      }
    },
    [onSave]
  );

  useEffect(() => {
    reset({ email });
  }, [reset, email]);

  useImperativeHandle(ref, () => {
    return {
      setFormError: setFormError,
    };
  });

  return (
    <form
      className="w-full flex flex-col items-center px-8"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <div className="mt-10 w-full">
        <Controller
          as={TextField}
          label={t("edit-profile-page_email")}
          rules={{
            required: t("edit-profile-page_email-required"),
          }}
          error={hasError("email")}
          helperText={getErrorMessage("email")}
          type="email"
          variant="outlined"
          className="w-full"
          name="email"
          control={control}
          defaultValue=""
        />
      </div>
      <FormError error={formError} />
      <div className="flex justify-center mt-5 w-full">
        <Button type="submit" variant="contained" color="primary">
          {t("edit-profile-page_save")}
        </Button>
      </div>
    </form>
  );
});

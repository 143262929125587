import React from "react";
import { ReactComponent as SkullAndBonesIcon } from "../assets/icons/skull-and-bones.svg";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const transition = {
  repeat: Infinity,
  duration: 2,
};

const animate = { y: [10, -5, 15, -10, 5, 0] };

export const LoadingSpinner = ({ textKey }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center w-full">
      <motion.div animate={animate} transition={transition}>
        <SkullAndBonesIcon />
      </motion.div>
      <p className="mt-2 text-xl font-bold">{t(textKey)}</p>
    </div>
  );
};

import { Auth } from "aws-amplify";
import Storage from "@aws-amplify/storage";

export const uploadFile = async (key, file, level = "public") => {
  const { attributes } = await Auth.currentUserInfo();
  const { key: resultKey } = await Storage.put(key, file, {
    level,
    metadata: {
      owner: attributes.sub,
    },
  });
  return resultKey;
};

import { atom } from "recoil";

export const studiosListState = atom({
  key: "studiosListState",
  default: [],
});

export const studiosNextTokenState = atom({
  key: "studiosNextTokenState",
  default: "",
});

export const studiosIsLoadingState = atom({
  key: "studiosIsLoadingState",
  default: false,
});

export const studiosIsLoadedState = atom({
  key: "studiosIsLoadedState",
  default: false,
});

export const studiosTotalState = atom({
  key: "studiosTotalState",
  default: 0,
});

import React, { useCallback, useState, useMemo } from "react";
import { NavigationHeader } from "../../components/NavigationHeader";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReactComponent as SkullAndBladesIcon } from "../../assets/icons/skull-and-blades.svg";
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Link,
} from "@material-ui/core";
import { FormError } from "../../components/FormError";
import { useErrors } from "../../hooks/useErrors";
import { useForgotPassword } from "../hooks/useForgotPassword";
import { useOnboarding } from "../hooks/useOnboarding";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

const preventDefault = (event) => {
  event.preventDefault();
};

export const ResetPassword = ({ email }) => {
  const { register, handleSubmit, errors } = useForm();

  const { hasError, getErrorMessage } = useErrors(errors);

  const { goToSignIn } = useOnboarding();

  const { t } = useTranslation();

  const [formError, setFormError] = useState(null);

  const [disabled, setDisabled] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const { submit, send } = useForgotPassword();

  const resendCode = useCallback(() => {
    send(email);
  }, [email, send]);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword((state) => !state);
  }, []);

  const passwordInputProps = useMemo(
    () => ({
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label={t("signin-page_img-show-password-alt")}
            onClick={handleClickShowPassword}
            onMouseDown={preventDefault}
            edge="end"
          >
            <EyeIcon />
          </IconButton>
        </InputAdornment>
      ),
    }),
    [handleClickShowPassword, t]
  );

  const onSubmit = useCallback(
    async ({ code, password }) => {
      try {
        setDisabled(true);
        await submit(email, code, password);
      } catch (error) {
        setFormError(error);
        setDisabled(false);
      }
    },
    [submit, email]
  );

  return (
    <article className="mx-auto flex flex-col items-center">
      <NavigationHeader
        title={t("forgot-password-page_header")}
        onActionClick={goToSignIn}
        icon={<CloseIcon />}
      />
      <SkullAndBladesIcon className="my-10" />
      <div className="px-8 text-graphite">
        <p>{t("forgot-password-page_enter-code")}</p>
      </div>
      <form
        className="w-full flex flex-col items-center px-8"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="mt-10 w-full">
          <TextField
            name="code"
            className="w-full"
            autoFocus={true}
            label={t("forgot-password-page_code-label")}
            variant="outlined"
            inputRef={register({
              required: t("forgot-password-page_code-required"),
            })}
            error={hasError("code")}
            helperText={getErrorMessage("code")}
          />
          <div className="flex justify-end mt-2">
            <Link
              className="font-bold"
              href="#"
              onClick={resendCode}
              color="primary"
            >
              {t("confirm-signup-page_resend-code")}
            </Link>
          </div>
        </div>
        <div className="my-6 w-full">
          <TextField
            name="password"
            type={showPassword ? "text" : "password"}
            className="w-full"
            autoComplete="new-password"
            label={t("forgot-password-page_password-label")}
            variant="outlined"
            inputRef={register({
              required: t("forgot-password-page_password-required"),
            })}
            InputProps={passwordInputProps}
            error={hasError("password")}
            helperText={getErrorMessage("password")}
          />
        </div>
        <FormError error={formError} />
        <div className="mt-6 w-full">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth={true}
            disabled={disabled}
          >
            {t("forgot-password-page_reset-password-button")}
          </Button>
        </div>
      </form>
    </article>
  );
};

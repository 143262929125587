import { useCallback } from "react";
import { get } from "react-hook-form";

export const useErrors = (errors) => {
  const hasError = useCallback((name) => !!get(errors, name), [errors]);

  const getErrorMessage = useCallback(
    (name) => {
      const error = get(errors, name);
      return error?.message || "";
    },
    [errors]
  );

  return { hasError, getErrorMessage };
};

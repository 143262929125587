import React from "react";
import splash1 from "../assets/images/splash_1.png";
import { useTranslation } from "react-i18next";
import { useDarkTheme } from "../hooks";
import { ReactComponent as Logo } from "../assets/images/tattd_logo.svg";

export const AuthLoading = () => {
  useDarkTheme();

  const { t } = useTranslation();

  return (
    <article className="mx-auto flex flex-col items-center relative">
      <div className="h-screen w-full relative">
        <div className="absolute inset-0 flex flex-col items-center">
          <Logo className="mt-auto mb-12 mx-auto" />
          <p className="mb-56 text-white text-center max-w-xs">v1.0</p>
        </div>
        <img
          className="w-full"
          src={splash1}
          alt={t("onboarding-page_img-splash-alt")}
        />
      </div>
    </article>
  );
};

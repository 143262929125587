import React, { useCallback } from "react";
import clsx from "clsx";
import { Avatar, Badge, IconButton } from "@material-ui/core";
import { v4 as uuid } from "uuid";
import { ReactComponent as EditPhoto } from "../assets/icons/edit-photo.svg";
import { S3Image } from "./S3Image";
import { getExtension } from "../utils";
import { Auth } from "aws-amplify";
import { ImagePurpose } from "../models";

export const ProfilePhoto = ({
  imgKey,
  isOwner,
  size = "lg",
  alt,
  intersectionOptions,
  onImageUpload,
}) => {
  const avatarClassNames = clsx({
    "w-12 h-12": size === "md",
    "w-20 h-20": size === "lg",
    "w-24 h-24": size === "xl",
  });

  const handleChange = useCallback(
    async (e) => {
      e.preventDefault();
      const {
        target: { value, files },
      } = e;
      const [file] = files || [];
      const selectedFile = file || value;

      const { attributes } = await Auth.currentUserInfo();

      if (selectedFile) {
        const { name: fileName } = selectedFile;
        const extension = getExtension(fileName);
        const key = `${attributes.sub}/${ImagePurpose.Profile}/${uuid()}${
          extension && "."
        }${extension}`;

        const inputFile = {
          key,
          localUri: selectedFile,
        };

        onImageUpload(inputFile);
      }
    },
    [onImageUpload]
  );

  return (
    <Badge
      invisible={!isOwner}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      badgeContent={
        <>
          <input
            id="add-profile-image-file-input"
            type="file"
            accept="image/*"
            onChange={handleChange}
            style={{ display: "none" }}
          />
          <label htmlFor="add-profile-image-file-input">
            <IconButton aria-label="upload cover" component="span">
              <EditPhoto />
            </IconButton>
          </label>
        </>
      }
      overlap="circle"
    >
      <Avatar alt={alt} className={avatarClassNames}>
        <S3Image
          imgKey={imgKey}
          intersectionOptions={intersectionOptions}
          variant="circle"
        />
      </Avatar>
    </Badge>
  );
};

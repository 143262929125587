const graphqlConjuctionFilter = (conjuction) => (...args) => {
  const filteredArgs = args.filter((arg) => !!arg);
  if (filteredArgs.length === 0) {
    return null;
  }
  return { [conjuction]: filteredArgs };
};

export const graphqlAndFilter = graphqlConjuctionFilter("and");

export const graphqlOrFilter = graphqlConjuctionFilter("or");

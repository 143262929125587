export const Price = {
  Low: "LOW",
  Moderate: "MODERATE",
  High: "HIGH",
  VeryHigh: "VERY_HIGH",
};

export const priceMapping = {
  [Price.Low]: "general_price-low-label",
  [Price.Moderate]: "general_price-moderate-label",
  [Price.High]: "general_price-high-label",
  [Price.VeryHigh]: "general_price-very-high-label",
};

import Auth from "@aws-amplify/auth";
import { ConsoleLogger as Logger } from "@aws-amplify/core";

import { useAuthContext } from "./useAuthContext";
import { useVerifyContact } from "./useVerifyContact";
import { AuthState } from "../data/authState";

const logger = new Logger("useSignIn");

export const useSignIn = () => {
  const { handleStateChange, authData } = useAuthContext();
  const verifyContact = useVerifyContact();

  const prefilledUsername = authData ? authData.username : "";

  const signIn = async (username, password, validationData) => {
    try {
      const user = await Auth.signIn({
        username,
        password,
        validationData,
      });
      logger.debug(user);
      if (
        user.challengeName === "SMS_MFA" ||
        user.challengeName === "SOFTWARE_TOKEN_MFA"
      ) {
        logger.debug("confirm user with " + user.challengeName);
        handleStateChange(AuthState.CONFIRM_SIGN_IN, user);
      } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        logger.debug("require new password", user.challengeParam);
        handleStateChange(AuthState.REQUIRE_NEW_PASSWORD, user);
      } else if (user.challengeName === "MFA_SETUP") {
        logger.debug("TOTP setup", user.challengeParam);
        handleStateChange(AuthState.TOTP_SETUP, user);
      } else {
        verifyContact(user);
      }
    } catch (error) {
      if (error.code === "UserNotConfirmedException") {
        logger.debug("the user is not confirmed");
        handleStateChange(AuthState.CONFIRM_SIGN_UP, { username });
      } else if (error.code === "PasswordResetRequiredException") {
        logger.debug("the user requires a new password");
        handleStateChange(AuthState.REQUIRE_NEW_PASSWORD, { username });
      } else {
        logger.error(error);
        throw error;
      }
    }
  };

  return { prefilledUsername, signIn };
};

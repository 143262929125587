import { useCallback } from "react";
import Auth from "@aws-amplify/auth";
import { ConsoleLogger as Logger } from "@aws-amplify/core";
import { useAuthContext } from "./useAuthContext";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { AuthState } from "../data/authState";

const logger = new Logger("useSignUp");

export const useSignUp = () => {
  const { handleStateChange } = useAuthContext();

  const signUp = useCallback(
    async (name, username, password, validationData, userType) => {
      const validationDataArray = [];

      if (validationData) {
        for (const [vdName, value] of Object.entries(validationData)) {
          validationDataArray.push(
            new CognitoUserAttribute({
              Name: vdName,
              Value: value,
            })
          );
        }
      }

      const signupInfo = {
        username,
        password,
        attributes: {
          name,
          "custom:userType": userType,
        },
        validationData: validationDataArray,
      };

      try {
        const data = await Auth.signUp(signupInfo);
        handleStateChange(AuthState.CONFIRM_SIGN_UP, {
          username: data.user.getUsername(),
        });
      } catch (error) {
        logger.error(error);
        throw error;
      }
    },
    [handleStateChange]
  );

  return { signUp };
};

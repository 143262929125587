import { useEffect, useState, useCallback, useMemo } from "react";
import { from } from "rxjs";
import { UserType } from "../models";
import {
  getStudioEditProfile,
  getArtistEditProfile,
  getPersonProfile,
  updatePersonById,
  updateArtistById,
  updateStudioById,
  searchArtistsByName,
  searchStudiosByName,
  confirmCollaborationRequest as confirmRequest,
  createCollaborationRequestByArtist as createRequestByArtist,
  createCollaborationRequestByStudio as createRequestByStudio,
  removeArtistStudio,
  removeCollaborationRequest as removeRequest,
} from "../api";

const getFetchEntity = (type) => {
  if (type === UserType.Person) {
    return getPersonProfile;
  }
  if (type === UserType.Artist) {
    return getArtistEditProfile;
  }
  if (type === UserType.Studio) {
    return getStudioEditProfile;
  }
  return () => {};
};

const getUpdateEntity = (type) => {
  if (type === UserType.Person) {
    return updatePersonById;
  }
  if (type === UserType.Artist) {
    return updateArtistById;
  }
  if (type === UserType.Studio) {
    return updateStudioById;
  }
  return () => {};
};

export const useEditProfile = (type, id) => {
  const [user, setUser] = useState();
  const [artists, setArtists] = useState([]);
  const [studios, setStudios] = useState([]);

  const getData = useMemo(() => getFetchEntity(type), [type]);

  const getProfile = useCallback(async () => {
    const response = await getData(id);
    setUser(response);
  }, [getData, id]);

  useEffect(() => {
    const subscription = from(getData(id)).subscribe(setUser);

    return () => subscription.unsubscribe();
  }, [getData, id]);

  const update = useCallback(
    (input) => {
      return getUpdateEntity(type)(input);
    },
    [type]
  );

  const updateFullName = useCallback(
    async (fullName) => {
      const response = await update({ id: user?.id, fullName });
      setUser(response);
    },
    [user, update, setUser]
  );

  const updateBio = useCallback(
    async (biography) => {
      const response = await update({ id: user?.id, biography });
      setUser(response);
    },
    [user, update, setUser]
  );

  const updateMyNextTat = useCallback(
    async (myNextTat) => {
      const response = await update({ id: user?.id, myNextTat });
      setUser(response);
    },
    [user, update, setUser]
  );

  const updateLocation = useCallback(
    async (location) => {
      const response = await update({ id: user?.id, location });
      setUser(response);
    },
    [user, update, setUser]
  );

  const fetchArtists = useCallback(async (value) => {
    if (!value) {
      setArtists([]);
    } else {
      const { artists: response } = await searchArtistsByName(value, 5);
      setArtists(response);
    }
  }, []);

  const fetchStudios = useCallback(async (value) => {
    if (!value) {
      setStudios([]);
    } else {
      const { studios: response } = await searchStudiosByName(value, 5);
      setStudios(response);
    }
  }, []);

  const confirmCollaborationRequest = useCallback(
    async (requestId) => {
      await confirmRequest(requestId);
      await getProfile();
    },
    [getProfile]
  );

  const createCollaborationRequestByStudio = useCallback(
    async (requesterId, requesteeID) => {
      await createRequestByStudio(requesterId, requesteeID);
      await getProfile();
    },
    [getProfile]
  );

  const createCollaborationRequestByArtist = useCallback(
    async (requesterId, requesteeID) => {
      await createRequestByArtist(requesterId, requesteeID);
      await getProfile();
    },
    [getProfile]
  );

  const removeConnection = useCallback(
    async (requestId) => {
      await removeArtistStudio(requestId);
      await getProfile();
    },
    [getProfile]
  );

  const removeCollaborationRequest = useCallback(
    async (requestId) => {
      await removeRequest(requestId);
      await getProfile();
    },
    [getProfile]
  );

  return {
    artists,
    studios,
    user,
    fetchStudios,
    fetchArtists,
    getProfile,
    updateFullName,
    updateBio,
    updateMyNextTat,
    updateLocation,
    confirmCollaborationRequest,
    createCollaborationRequestByStudio,
    createCollaborationRequestByArtist,
    removeConnection,
    removeCollaborationRequest,
  };
};

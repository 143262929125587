import React from "react";
import { Box } from "@material-ui/core";
import { BottomNavigationBar } from "./BottomNavigationBar";

export const Layout = ({ children, showBottomNavigation = true }) => {
  return (
    <Box className="flex flex-col h-screen">
      <Box id="layout-scroll" className="flex-grow overflow-scroll">
        {children}
      </Box>
      <Box>{showBottomNavigation && <BottomNavigationBar />}</Box>
    </Box>
  );
};

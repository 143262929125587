import React, { useState, useEffect, useCallback } from "react";
import { ReactComponent as FilledHeart } from "../assets/icons/heart-filled.svg";
import { ReactComponent as StrokedHeart } from "../assets/icons/heart-stroked.svg";
import { IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const preventDefault = (event) => {
  event.preventDefault();
};

export const LikedToggle = ({ liked = false, size = "medium" }) => {
  const { t } = useTranslation();

  const [isLiked, setIsLiked] = useState(liked);

  const onClick = useCallback(() => setIsLiked((state) => !state), []);

  useEffect(() => {
    setIsLiked(liked);
  }, [liked]);

  return (
    <IconButton
      aria-label={t("action-button_like-item-alt")}
      onClick={onClick}
      onMouseDown={preventDefault}
      size={size}
    >
      {isLiked ? <FilledHeart /> : <StrokedHeart />}
    </IconButton>
  );
};

import React, { useState } from "react";
import { S3Image } from "../S3Image";
import { useTranslation } from "react-i18next";
import { LikedToggle } from "../LikedToggle";
import { SocialShare } from "../SocialShare";
import { SendMessage } from "../SendMessage";
import { useMount } from "react-use";
import { getPostPreview } from "../../api/PostApi";
import { Specialization } from "../../models";
import { Link } from "react-router-dom";
import { UserType } from "../../models/UserType";

export const PostModal = ({ id }) => {
  const { t } = useTranslation();

  const [post, setPost] = useState(null);

  useMount(async () => {
    const data = await getPostPreview(id);
    setPost(data);
  });

  const artistLink = `/profile/${UserType.Artist}/${post?.artist.id}`;

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <article className="flex flex-col items-center">
      <div className="rounded-lg overflow-hidden">
        <S3Image
          alt={post.id}
          imgKey={post.image.key}
          ImageProps={{
            className: "w-full",
          }}
        />
      </div>
      <div className="flex flex-col mt-4 items-center">
        {post.title && (
          <p className="font-bold text-center mb-1">{post.title}</p>
        )}
        <Link to={artistLink}>
          {t("image-card_image-by-artist", {
            artistFullName: post.artist.fullName,
          })}
        </Link>
      </div>
      <div className="flex mt-3 justify-center">
        <LikedToggle />
        <SocialShare />
        <SendMessage />
      </div>
      <div className="flex flex-wrap justify-center mt-10">
        {post.specializations.map((specialization, index) => (
          <Hashtag
            key={index}
            text={t(`${Specialization[specialization]}-label`)}
          />
        ))}
        {post.keywords.map((keyword, index) => (
          <Hashtag key={index} text={keyword} />
        ))}
      </div>
    </article>
  );
};

const Hashtag = ({ text }) => {
  const hashtag = text.indexOf("#") > -1 ? text : `#${text}`;
  return <p className="m-1 text-ash text-sm">{hashtag}</p>;
};

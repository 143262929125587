import React, { useCallback } from "react";
import { Auth } from "aws-amplify";
import { IconButton } from "@material-ui/core";
import { v4 as uuid } from "uuid";
import { ReactComponent as EditPhoto } from "../assets/icons/edit-photo.svg";
import { S3Image } from "./S3Image";
import { ImagePurpose } from "../models";
import { getExtension } from "../utils";

export const CoverPhoto = ({ imgKey, isOwner, onImageUpload }) => {
  const handleChange = useCallback(
    async (e) => {
      e.preventDefault();
      const {
        target: { value, files },
      } = e;
      const [file] = files || [];
      const selectedFile = file || value;

      const { attributes } = await Auth.currentUserInfo();

      if (selectedFile) {
        const { name: fileName } = selectedFile;
        const extension = getExtension(fileName);
        const key = `${attributes.sub}/${ImagePurpose.Cover}/${uuid()}${
          extension && "."
        }${extension}`;

        const inputFile = {
          key,
          localUri: selectedFile,
        };

        onImageUpload(inputFile);
      }
    },
    [onImageUpload]
  );

  return (
    <div className="relative h-32">
      <S3Image
        imgKey={imgKey}
        ImageProps={{ className: "w-full h-full object-cover" }}
        alt="header"
      />
      {isOwner && (
        <>
          <input
            id="add-cover-image-file-input"
            type="file"
            accept="image/*"
            onChange={handleChange}
            style={{ display: "none" }}
          />
          <label htmlFor="add-cover-image-file-input">
            <IconButton
              className="absolute right-1 top-20 z-10"
              aria-label="upload cover"
              component="span"
            >
              <EditPhoto />
            </IconButton>
          </label>
        </>
      )}
    </div>
  );
};

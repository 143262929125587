import React, { useCallback } from "react";
import { Tabs as MuiTabs } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Tab } from "./Tab";

export const Tabs = ({ tabs, selectedTab, onSelectedTab }) => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (_, newValue) => {
      onSelectedTab(newValue);
    },
    [onSelectedTab]
  );

  return (
    <MuiTabs
      value={selectedTab}
      onChange={handleChange}
      textColor="primary"
      className="flex flex-row w-full h-8 justify-around"
    >
      {tabs.map((tab, index) => (
        <Tab
          value={index}
          isActive={selectedTab === index}
          key={index}
          label={t(tab.label)}
        />
      ))}
    </MuiTabs>
  );
};

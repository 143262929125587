import { useCallback } from "react";
import { searchStudios } from "../../../api/StudioApi";
import { useEventCallback } from "rxjs-hooks";
import { tap, concatMap, take, flatMap, takeUntil } from "rxjs/operators";
import { from, Subject } from "rxjs";
import { useRecoilState } from "recoil";
import {
  studiosListState,
  studiosNextTokenState,
  studiosIsLoadingState,
  studiosIsLoadedState,
  studiosTotalState,
} from "./studiosListState";

export const useSearchStudios = ({ location, specializations, price }) => {
  const [nextToken, setNextToken] = useRecoilState(studiosNextTokenState);
  const [isLoading, setIsLoading] = useRecoilState(studiosIsLoadingState);
  const [isLoaded, setIsLoaded] = useRecoilState(studiosIsLoadedState);
  const [total, setTotal] = useRecoilState(studiosTotalState);

  const [items, setItems] = useRecoilState(studiosListState);

  const cancel$ = new Subject();

  const [fetchItems] = useEventCallback(
    (event$, _, input$) =>
      event$.pipe(
        concatMap(() =>
          input$.pipe(
            take(1),
            tap(() => setIsLoading(true)),
            flatMap(([location, specializations, price, nextToken]) =>
              from(
                searchStudios(location, specializations, price, nextToken)
              ).pipe(takeUntil(cancel$))
            )
          )
        ),
        tap((response) => {
          setItems((state) => [...state, ...response.items]);
          setNextToken(response.nextToken);
          setTotal(response.total);
          setIsLoading(false);
          setIsLoaded(true);
        })
      ),
    [],
    [location, specializations, price, nextToken]
  );

  const hasMore = nextToken !== null && items.length < total;

  const reload = useCallback(() => {
    cancel$.next();
    setIsLoaded(false);
    setIsLoading(false);
    setItems([]);
    setNextToken("");
    fetchItems();
  }, [cancel$, fetchItems, setIsLoaded, setIsLoading, setItems, setNextToken]);

  return {
    items,
    fetchItems,
    hasMore,
    isLoading,
    isLoaded,
    reload,
  };
};

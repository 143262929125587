import React, { useCallback, useState } from "react";
import { NavigationHeader } from "../../components/NavigationHeader";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReactComponent as SkullAndBladesIcon } from "../../assets/icons/skull-and-blades.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { Button, TextField } from "@material-ui/core";
import { FormError } from "../../components/FormError";
import { useErrors } from "../../hooks/useErrors";
import { useForgotPassword } from "../hooks/useForgotPassword";
import { useOnboarding } from "../hooks/useOnboarding";

export const SendResetCode = ({ onResetCodeSent }) => {
  const { register, handleSubmit, errors } = useForm();

  const { hasError, getErrorMessage } = useErrors(errors);

  const { goToSignIn } = useOnboarding();

  const { t } = useTranslation();

  const [formError, setFormError] = useState(null);

  const [disabled, setDisabled] = useState(false);

  const { send } = useForgotPassword();

  const onSubmit = useCallback(
    async ({ email }) => {
      try {
        setDisabled(true);
        await send(email);
        onResetCodeSent(email);
      } catch (error) {
        setFormError(error);
        setDisabled(false);
      }
    },
    [onResetCodeSent, send]
  );

  return (
    <article className="mx-auto flex flex-col items-center">
      <NavigationHeader
        title={t("forgot-password-page_header")}
        onActionClick={goToSignIn}
        icon={<CloseIcon />}
      />
      <SkullAndBladesIcon className="my-10" />
      <strong className="mb-10">{t("forgot-password-page_title")}</strong>
      <div className="px-8 text-graphite">
        <p className="mb-4">{t("forgot-password-page_enter-email")}</p>
        <p>{t("forgot-password-page_email-description")}</p>
      </div>
      <form
        className="w-full flex flex-col items-center px-8"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="mt-12 mb-6 w-full">
          <TextField
            name="email"
            className="w-full"
            autoComplete="email"
            autoFocus={true}
            label={t("forgot-password-page_email-label")}
            variant="outlined"
            inputRef={register({
              required: t("forgot-password-page_email-required"),
            })}
            error={hasError("email")}
            helperText={getErrorMessage("email")}
          />
        </div>
        <FormError error={formError} />
        <div className="mt-6 w-full">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth={true}
            disabled={disabled}
          >
            {t("forgot-password-page_send-button")}
          </Button>
        </div>
      </form>
    </article>
  );
};

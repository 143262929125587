import React, { useMemo } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { InputAdornment, IconButton, TextField } from "@material-ui/core";
import { ReactComponent as PinIcon } from "../../assets/icons/pin.svg";

export const LocationInput = ({ InputProps, ...props }) => {
  const { t } = useTranslation();

  const locationInputProps = useMemo(
    () => ({
      ...InputProps,
      startAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label={t("discover-page_img-location-alt")}
            edge="start"
          >
            <PinIcon />
          </IconButton>
        </InputAdornment>
      ),
      className: clsx("rounded-lg", InputProps.className),
    }),
    [t, InputProps]
  );

  return (
    <TextField
      name="location"
      className="w-full"
      label={t("discover-page_location-label")}
      variant="outlined"
      InputProps={locationInputProps}
      {...props}
    />
  );
};

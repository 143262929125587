import API, { graphqlOperation } from "@aws-amplify/api";
import {
  searchPosts as searchPostsGraphQL,
  getPostPreview as getPostPreviewGraphQL,
  postsByArtist,
} from "../graphql/custom-queries";
import { pickBy, identity } from "lodash";
import { graphqlAndFilter, graphqlOrFilter } from "./utils";

const errorHandler = (e) => alert("Something went terribly wrong:", e);

export const searchPosts = async (
  location = null,
  specializations = [],
  price = null,
  keywords = [],
  nextToken = null,
  limit = 21
) => {
  const filter = graphqlAndFilter(
    location && { location: { eq: location } },
    graphqlOrFilter(
      ...keywords.map((keyword) => ({ keywords: { eq: keyword } }))
    ),
    graphqlOrFilter(
      ...specializations.map((specialization) => ({
        specializations: { eq: specialization },
      }))
    )
    // TODO Revert price when more data is available
    // price && { pricePoint: { eq: price } }
  );

  const query = pickBy(
    {
      limit,
      filter,
      nextToken,
    },
    identity
  );

  try {
    const { data } = await API.graphql(
      graphqlOperation(searchPostsGraphQL, query)
    );
    return data.searchPosts;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

export const getPostsByArtist = async (
  artistId,
  nextToken = null,
  limit = 21
) => {
  const query = pickBy(
    {
      artistID: artistId,
      nextToken,
      limit,
    },
    identity
  );

  try {
    const { data } = await API.graphql(graphqlOperation(postsByArtist, query));
    return data.postsByArtist;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

export const getPostPreview = async (id) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(getPostPreviewGraphQL, { id })
    );
    return data.getPost;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

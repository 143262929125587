export const searchArtists = /* GraphQL */ `
  query SearchArtists(
    $filter: SearchableArtistFilterInput
    $sort: SearchableArtistSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchArtists(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        profilePic {
          key
        }
        fullName
        location
        pricePoint
        specializations
        likedByViewer
        posts {
          items {
            id
            image {
              key
            }
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;

export const searchStudios = /* GraphQL */ `
  query SearchStudios(
    $filter: SearchableStudioFilterInput
    $sort: SearchableStudioSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchStudios(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        profilePic {
          key
        }
        coverPic {
          key
        }
        fullName
        location
        pricePoint
        likedByViewer
      }
      nextToken
      total
    }
  }
`;

export const searchPosts = /* GraphQL */ `
  query SearchPosts(
    $filter: SearchablePostFilterInput
    $sort: SearchablePostSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchPosts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        image {
          key
        }
        title
        keywords
        likedByViewer
        artist {
          id
          fullName
        }
      }
      nextToken
      total
    }
  }
`;

export const getLikedStudios = /* GraphQL */ `
  query GetLikedStudios($id: ID!, $limit: Int, $nextToken: String) {
    getLikedStudios(id: $id, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        profilePic {
          key
        }
        coverPic {
          key
        }
        fullName
        location
        likedByViewer
      }
      nextToken
    }
  }
`;

export const getStudioProfile = /* GraphQL */ `
  query GetStudio($id: ID!) {
    getStudio(id: $id) {
      id
      owner
      profilePic {
        key
      }
      coverPic {
        key
      }
      fullName
      location
      biography
      artists {
        items {
          id
          profilePic {
            bucket
            region
            key
            visibility
          }
          fullName
        }
      }
      hourlyRate
      pricePoint
      specializations
      likedByViewer
    }
  }
`;

export const getStudioEditProfile = /* GraphQL */ `
  query GetStudio($id: ID!) {
    getStudio(id: $id) {
      id
      fullName
      location
      biography
      artists {
        items {
          id
          profilePic {
            key
          }
          fullName
          location
          pricePoint
        }
      }
      hourlyRate
      pricePoint
      specializations
      likedByViewer
      collaborationRequests {
        items {
          id
          ownerID
          artistID
          studioID
          artist {
            id
            profilePic {
              key
            }
            fullName
            location
            pricePoint
          }
        }
        nextToken
      }
    }
  }
`;

export const getArtistProfile = /* GraphQL */ `
  query GetArtist($id: ID!) {
    getArtist(id: $id) {
      id
      owner
      profilePic {
        key
      }
      fullName
      location
      biography
      hourlyRate
      pricePoint
      specializations
      posts {
        items {
          id
          title
          image {
            key
          }
          keywords
          specializations
          likedByViewer
        }
        nextToken
      }
      studio {
        id
        fullName
      }
      likedByViewer
    }
  }
`;

export const getArtistEditProfile = /* GraphQL */ `
  query GetArtist($id: ID!) {
    getArtist(id: $id) {
      id
      profilePic {
        key
      }
      fullName
      location
      biography
      hourlyRate
      pricePoint
      specializations
      studio {
        id
        profilePic {
          key
        }
        fullName
        location
        pricePoint
      }
      collaborationRequests {
        items {
          id
          ownerID
          artistID
          studioID
          studio {
            id
            profilePic {
              key
            }
            fullName
            location
            pricePoint
          }
        }
        nextToken
      }
    }
  }
`;

export const getPersonProfile = /* GraphQL */ `
  query GetPerson($id: ID!) {
    getPerson(id: $id) {
      id
      profilePic {
        key
      }
      coverPic {
        key
      }
      fullName
      location
      biography
      myNextTat
      moodboards {
        id
        title
        images {
          key
        }
      }
    }
  }
`;

export const getPostPreview = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      title
      image {
        key
      }
      keywords
      specializations
      artist {
        id
        fullName
      }
    }
  }
`;

export const postsByArtist = /* GraphQL */ `
  query PostsByArtist(
    $artistID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByArtist(
      artistID: $artistID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        image {
          key
        }
        title
        keywords
        likedByViewer
        artist {
          id
          fullName
        }
      }
      nextToken
    }
  }
`;

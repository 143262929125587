import React, { useState, useCallback, useEffect, forwardRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TextField, Button } from "@material-ui/core";
import { useErrors } from "../../../hooks";
import { FormError } from "../../../components";

export const ProfileNameItem = forwardRef(({ fullName, onSave }, ref) => {
  const { t } = useTranslation();
  const { handleSubmit, errors, reset, control } = useForm();
  const { hasError, getErrorMessage } = useErrors(errors);
  const [formError, setFormError] = useState(null);

  const onSubmit = useCallback(
    async ({ fullName: fullNameToSave }) => {
      try {
        onSave(fullNameToSave);
      } catch (error) {
        setFormError(error);
      }
    },
    [onSave]
  );

  useEffect(() => {
    reset({ fullName });
  }, [reset, fullName]);

  return (
    <form
      className="w-full flex flex-col items-center px-8"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      ref={ref}
    >
      <div className="mt-10 w-full">
        <Controller
          as={TextField}
          label={t("edit-profile-page_name")}
          rules={{ required: t("edit-profile-page_name-required") }}
          error={hasError("fullName")}
          helperText={getErrorMessage("fullName")}
          type="text"
          variant="outlined"
          className="w-full"
          name="fullName"
          control={control}
          defaultValue=""
        />
      </div>
      <FormError error={formError} />
      <div className="flex justify-center mt-5 w-full">
        <Button type="submit" variant="contained" color="primary">
          {t("edit-profile-page_save")}
        </Button>
      </div>
    </form>
  );
});

import React from "react";
import { useParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import { PersonProfile } from "./PersonProfile";
import { ArtistProfile } from "./ArtistProfile";
import { StudioProfile } from "./StudioProfile";
import { UserType } from "../../models";
import { useProfile } from "../../hooks";

export const Profile = () => {
  const { id, type } = useParams();
  const { user } = useProfile(type, id);
  const { attributes } = Auth.user;

  const isOwner = id === attributes.sub;

  if (!user) {
    return null;
  }

  if (type === UserType.Person) {
    return <PersonProfile isOwner={isOwner} user={user} />;
  }

  if (type === UserType.Artist) {
    return <ArtistProfile isOwner={isOwner} user={user} />;
  }

  if (type === UserType.Studio) {
    return <StudioProfile isOwner={isOwner} user={user} />;
  }

  return null;
};

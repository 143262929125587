import React, { useCallback, useState } from "react";
import { SendResetCode } from "./SendResetCode";
import { ResetPassword } from "./ResetPassword";

const ForgotPasswordPage = {
  SendResetCode: "SEND_RESET_CODE",
  ResetPassword: "RESET_PASSWORD",
};

export const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const [page, setPage] = useState(ForgotPasswordPage.SendResetCode);

  const onResetCodeSent = useCallback((emailValue) => {
    setPage(ForgotPasswordPage.ResetPassword);
    setEmail(emailValue);
  }, []);

  if (page === ForgotPasswordPage.SendResetCode) {
    return <SendResetCode onResetCodeSent={onResetCodeSent} />;
  }

  if (page === ForgotPasswordPage.ResetPassword) {
    return <ResetPassword email={email} />;
  }

  return null;
};

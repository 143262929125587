import React, { useCallback } from "react";
import { ReactComponent as MessageIcon } from "../assets/icons/message-fly.svg";
import { IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const preventDefault = (event) => {
  event.preventDefault();
};

export const SendMessage = ({ size = "medium" }) => {
  const { t } = useTranslation();

  const onClick = useCallback(() => {
    // TODO send message to provided contact
  }, []);

  const iconDimensions = {
    medium: { height: "20.85", width: "23.76" },
    small: { height: "19.9", width: "22.68" },
  };

  return (
    <IconButton
      aria-label={t("action-button_send-message-alt")}
      onClick={onClick}
      onMouseDown={preventDefault}
      size={size}
    >
      <MessageIcon
        height={iconDimensions[size].height}
        width={iconDimensions[size].width}
      />
    </IconButton>
  );
};

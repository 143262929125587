import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Divider } from "@material-ui/core";
import {
  CoverPhoto,
  ProfilePhoto,
  SpecializationChip,
  SendMessage,
} from "../../../components";
import { uploadStudioProfileImage, uploadStudioCoverImage } from "../../../api";
import { Specialization, UserType, priceMapping } from "../../../models";

export const StudioProfile = ({ user, isOwner }) => {
  const { t } = useTranslation();
  const [studio, setStudio] = useState(user);

  const handleProfilePhotoUpload = useCallback(
    async (file) => {
      const response = await uploadStudioProfileImage(studio.id, file);
      setStudio(response);
    },
    [studio]
  );

  const handleCoverPhotoUpload = useCallback(
    async (file) => {
      const response = await uploadStudioCoverImage(studio.id, file);
      setStudio(response);
    },
    [studio]
  );

  return (
    <article>
      <div className="mb-8 relative">
        <CoverPhoto
          imgKey={studio.coverPic?.key}
          isOwner={isOwner}
          onImageUpload={handleCoverPhotoUpload}
        />
        <div className="absolute inset-x-0 top-24 flex justify-center">
          <ProfilePhoto
            imgKey={studio.profilePic?.key}
            isOwner={isOwner}
            size="md"
            onImageUpload={handleProfilePhotoUpload}
          />
        </div>
        {!isOwner && (
          <div className="absolute right-5 top-36">
            <SendMessage />
          </div>
        )}
        <div className="mt-12 mx-3">
          <h1 className="text-lg font-bold text-center">{studio.fullName}</h1>
          <h2 className="font-light text-center">{studio.location}</h2>
          <h3 className="font-bold text-center">
            {studio.pricePoint && t(priceMapping[studio.pricePoint])}
          </h3>
          {isOwner && (
            <div className="text-center">
              <Link
                className="font-bold x text-wine lowercase"
                to={`/edit-profile/${UserType.Studio}/${studio.id}`}
              >
                {t("profile-page_edit-profile")}
              </Link>
            </div>
          )}
          <p className="font-normal mt-8">{studio.biography}</p>
        </div>
        {studio.specializations?.length > 0 && (
          <div className="flex justify-center">
            {studio.specializations.map((spec) => {
              return (
                <SpecializationChip
                  key={`spec-${spec}`}
                  label={t(Specialization[spec])}
                />
              );
            })}
          </div>
        )}
      </div>
      <Divider />
      <div className="m-4">
        <h1 className="text-2xl font-bold">{t("profile-page_artists")}</h1>
        <div className="flex justify-between mt-8">
          {studio?.artists?.items.map((artist) => {
            return (
              <div key={`art-${artist.id}`} className="flex flex-col">
                <ProfilePhoto
                  imgKey={artist?.profilePic?.key}
                  size="xl"
                  alt={artist.fullName}
                />
                <span className="text-xs text-graphite truncate mt-1 text-center">
                  {artist.fullName}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </article>
  );
};

import React, { useCallback } from "react";
import { Button, ButtonGroup } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { priceMapping } from "../../models/Price";

export const PricesFilter = ({ activePrice, onChange }) => {
  const { t } = useTranslation();
  return (
    <article className="flex flex-col px-4 pt-6">
      <h3 className="text-lg font-bold mb-3">
        {t("discover-page_price-filter-title")}
      </h3>
      <ButtonGroup>
        {Object.keys(priceMapping).map((key, index) => (
          <PriceFilterButton
            key={index}
            priceValue={key}
            isActive={activePrice && activePrice.value === key}
            label={t(priceMapping[key])}
            onChange={onChange}
          />
        ))}
      </ButtonGroup>
    </article>
  );
};

const PriceFilterButton = ({
  priceValue,
  isActive,
  label,
  onChange,
  ...props
}) => {
  const color = isActive ? "primary" : "default";
  const variant = isActive ? "contained" : "outlined";
  const onClick = useCallback(() => {
    onChange({ value: priceValue, label });
  }, [onChange, priceValue, label]);

  const className = clsx(
    props.className,
    "px-4 py-1 text-sm normal-case font-normal"
  );

  return (
    <Button
      {...props}
      type="button"
      onClick={onClick}
      className={className}
      variant={variant}
      color={color}
    >
      {label}
    </Button>
  );
};

import API, { graphqlOperation } from "@aws-amplify/api";
import { getPersonProfile as getPersonProfileGraphQL } from "../graphql/custom-queries";
import { updatePerson } from "../graphql/mutations";
import awsExports from "../aws-exports";
import { uploadFile } from "./StorageApi";

const errorHandler = (e) => alert("Something went terribly wrong:", e);

export const getPersonProfile = async (id) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(getPersonProfileGraphQL, { id })
    );
    return data.getPerson;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

export const updatePersonById = async (input) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(updatePerson, { input })
    );
    return data.updatePerson;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

export const uploadPersonCoverImage = async (id, file) => {
  const visibility = "public";
  try {
    const key = await uploadFile(file.key, file.localUri, visibility);
    const { data } = await API.graphql(
      graphqlOperation(updatePerson, {
        input: {
          id,
          coverPic: {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key,
            visibility,
          },
        },
      })
    );
    return data.updatePerson;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

export const uploadPersonProfileImage = async (id, file) => {
  const visibility = "public";
  try {
    const key = await uploadFile(file.key, file.localUri, visibility);
    const { data } = await API.graphql(
      graphqlOperation(updatePerson, {
        input: {
          id,
          profilePic: {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key,
            visibility,
          },
        },
      })
    );
    return data.updatePerson;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

import React, { useCallback, useMemo } from "react";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FilterName } from "./FilterName";

const truncate = (str, n) => {
  return str.length > n ? str.substr(0, n - 1) + "&hellip;" : str;
};

export const FilterByButtons = ({
  activeFilterName,
  onChange,
  specializations,
  price,
  keywords,
  disableKeywords,
}) => {
  const { t } = useTranslation();

  const hasSpecializations = specializations.length > 0;

  const specializationsLabel = useMemo(() => {
    const labelSuffix = hasSpecializations ? "+" : "";
    const label = t("discover-page_specialization-filter-button");
    return `${label} ${labelSuffix}`;
  }, [hasSpecializations, t]);

  const hasPrice = !!price;

  const priceLabel = useMemo(() => {
    if (hasPrice) {
      return t(price.label);
    }
    return t("discover-page_price-filter-button");
  }, [hasPrice, price, t]);

  const hasKeywords = keywords.length > 0;

  const keywordsLabel = useMemo(() => {
    if (hasKeywords) {
      return `${truncate(keywords[0], 5)} +`;
    }
    return t("discover-page_keywords-filter-button");
  }, [hasKeywords, keywords, t]);

  return (
    <div className="flex justify-around mt-3">
      <FilterByButton
        label={specializationsLabel}
        onChange={onChange}
        hasValue={hasSpecializations}
        isActive={activeFilterName === FilterName.Specialization}
        filterName={FilterName.Specialization}
      />
      <FilterByButton
        label={priceLabel}
        onChange={onChange}
        hasValue={hasPrice}
        isActive={activeFilterName === FilterName.Price}
        filterName={FilterName.Price}
      />
      <FilterByButton
        label={keywordsLabel}
        onChange={onChange}
        hasValue={hasKeywords}
        isActive={activeFilterName === FilterName.Keywords}
        filterName={FilterName.Keywords}
        disabled={disableKeywords}
      />
    </div>
  );
};

const FilterByButton = ({
  label,
  isActive,
  filterName,
  onChange,
  hasValue,
  disabled = false,
}) => {
  const color = isActive || hasValue ? "primary" : "default";
  const variant = isActive ? "contained" : "outlined";
  const onClick = useCallback(() => onChange(filterName), [
    filterName,
    onChange,
  ]);
  return (
    <Button
      className="px-8 py-1 text-sm capitalize font-normal rounded-lg"
      onClick={onClick}
      variant={variant}
      color={color}
      disabled={disabled}
    >
      <span dangerouslySetInnerHTML={{ __html: label }} />
    </Button>
  );
};

import Auth from "@aws-amplify/auth";

import { isEmpty } from "lodash";
import { useAuthContext } from "./useAuthContext";
import { AuthState } from "../data/authState";

export const useVerifyContact = () => {
  const { handleStateChange } = useAuthContext();

  return async (authData) => {
    const data = await Auth.verifiedContact(authData);

    if (!isEmpty(data.verified)) {
      handleStateChange(AuthState.SIGNED_IN, authData);
    } else {
      const newUser = Object.assign(authData, data);
      handleStateChange(AuthState.VERIFY_CONTACT, newUser);
    }
  };
};

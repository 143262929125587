import API, { graphqlOperation } from "@aws-amplify/api";
import { updateStudio } from "../graphql/custom-mutations";
import awsExports from "../aws-exports";
import {
  searchStudios as searchStudiosGraphQL,
  getLikedStudios as getLikedStudiosGraphQL,
  getStudioProfile as getStudioProfileGraphQL,
  getStudioEditProfile as getStudioEditProfileGraphQL,
} from "../graphql/custom-queries";
import { pickBy, identity } from "lodash";
import { graphqlAndFilter, graphqlOrFilter } from "./utils";
import { uploadFile } from "./StorageApi";

const errorHandler = (e) => alert("Something went terribly wrong:", e);

export const searchStudios = async (
  location = null,
  specializations = [],
  price = null,
  nextToken = null,
  limit = 20
) => {
  const filter = graphqlAndFilter(
    location && { location: { eq: location } },
    graphqlOrFilter(
      ...specializations.map((specialization) => ({
        specializations: { eq: specialization },
      }))
    ),
    price && { pricePoint: { eq: price } }
  );

  const query = pickBy(
    {
      limit,
      filter,
      nextToken,
    },
    identity
  );
  try {
    const { data } = await API.graphql(
      graphqlOperation(searchStudiosGraphQL, query)
    );
    return data.searchStudios;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

export const searchStudiosByName = async (searchValue, limit = 20) => {
  const filters = { or: [] };
  if (searchValue) {
    filters.or.push({ owner: { matchPhrasePrefix: searchValue } });
    filters.or.push({ fullName: { matchPhrasePrefix: searchValue } });
  }
  const query = filters.or.length > 0 ? { filter: filters, limit } : { limit };
  try {
    const { data } = await API.graphql(
      graphqlOperation(searchStudiosGraphQL, query)
    );
    const { items: studios, nextToken } = data.searchStudios;
    return { studios, nextToken };
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

export const getStudioProfile = async (id) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(getStudioProfileGraphQL, { id })
    );
    return data.getStudio;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

export const getStudioEditProfile = async (id) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(getStudioEditProfileGraphQL, { id })
    );
    return data.getStudio;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

export const updateStudioById = async (input) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(updateStudio, { input })
    );
    return data.updateStudio;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

export const getLikedStudios = async (id, nextToken = null, limit = 20) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(getLikedStudiosGraphQL, { id, limit })
    );
    return data.getLikedStudios;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

export const uploadStudioProfileImage = async (id, file) => {
  const visibility = "public";
  try {
    const key = await uploadFile(file.key, file.localUri, visibility);
    const { data } = await API.graphql(
      graphqlOperation(updateStudio, {
        input: {
          id,
          profilePic: {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key,
            visibility,
          },
        },
      })
    );
    return data.updateStudio;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

export const uploadStudioCoverImage = async (id, file) => {
  const visibility = "public";
  try {
    const key = await uploadFile(file.key, file.localUri, visibility);
    const { data } = await API.graphql(
      graphqlOperation(updateStudio, {
        input: {
          id,
          coverPic: {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key,
            visibility,
          },
        },
      })
    );
    return data.updateStudio;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

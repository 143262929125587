import React, {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { TextField, Button } from "@material-ui/core";
import { useErrors } from "../../../hooks";
import { FormError } from "../../../components";

export const PasswordItem = forwardRef(({ onSave }, ref) => {
  const { t } = useTranslation();
  const { control, handleSubmit, errors, reset } = useForm();
  const { hasError, getErrorMessage } = useErrors(errors);
  const [formError, setFormError] = useState(null);

  const onSubmit = useCallback(
    async ({ currentPassword, password, confirmPassword }) => {
      try {
        if (password !== confirmPassword) {
          setFormError({ message: t("edit-profile-page_password-match") });
        } else {
          await onSave(currentPassword, password);
        }
      } catch (error) {
        setFormError(error);
      } finally {
        reset({ currentPassword: "", password: "", confirmPassword: "" });
      }
    },
    [t, onSave, reset]
  );

  useImperativeHandle(ref, () => {
    return {
      setFormError: setFormError,
    };
  });

  return (
    <form
      className="w-full flex flex-col items-center px-8"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      ref={ref}
    >
      <div className="mt-6 w-full">
        <Controller
          as={TextField}
          label={t("edit-profile-page_current-password")}
          rules={{
            required: t("edit-profile-page_current-password-required"),
          }}
          error={hasError("currentPassword")}
          helperText={getErrorMessage("currentPassword")}
          type="password"
          variant="outlined"
          className="w-full"
          name="currentPassword"
          control={control}
          defaultValue=""
        />
      </div>
      <div className="mt-6 w-full">
        <Controller
          as={TextField}
          label={t("edit-profile-page_password")}
          rules={{
            required: t("edit-profile-page_password-required"),
          }}
          error={hasError("password")}
          helperText={getErrorMessage("password")}
          type="password"
          variant="outlined"
          className="w-full"
          name="password"
          control={control}
          defaultValue=""
        />
      </div>
      <div className="mt-6 mb-5 w-full">
        <Controller
          as={TextField}
          label={t("edit-profile-page_confirm-password")}
          rules={{
            required: t("edit-profile-page_confirm-password-required"),
          }}
          error={hasError("confirmPassword")}
          helperText={getErrorMessage("confirmPassword")}
          type="password"
          variant="outlined"
          className="w-full"
          name="confirmPassword"
          control={control}
          defaultValue=""
        />
      </div>
      <FormError error={formError} />
      <div className="flex justify-center mt-5 w-full">
        <Button type="submit" variant="contained" color="primary">
          {t("edit-profile-page_save")}
        </Button>
      </div>
    </form>
  );
});

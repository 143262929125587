import API, { graphqlOperation } from "@aws-amplify/api";
import {
  confirmCollaborationRequest as confirmCollaborationRequestGraphQL,
  createCollaborationRequest as createCollaborationRequestGraphQL,
  deleteCollaborationRequest,
} from "../graphql/mutations";

const errorHandler = (e) => alert("Something went terribly wrong:", e);

export const confirmCollaborationRequest = async (id) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(confirmCollaborationRequestGraphQL, { input: { id } })
    );
    return data.confirmCollaborationRequest;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

export const createCollaborationRequestByStudio = async (
  requesterId,
  requesteeId
) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(createCollaborationRequestGraphQL, {
        input: {
          ownerID: requesterId,
          artistID: requesteeId,
          studioID: requesterId,
        },
      })
    );
    return data.createCollaborationRequest;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

export const createCollaborationRequestByArtist = async (
  requesterId,
  requesteeId
) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(createCollaborationRequestGraphQL, {
        input: {
          ownerID: requesterId,
          artistID: requesterId,
          studioID: requesteeId,
        },
      })
    );
    return data.createCollaborationRequest;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

export const removeCollaborationRequest = async (requestId) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(deleteCollaborationRequest, {
        input: {
          id: requestId,
        },
      })
    );
    return data.deleteCollaborationRequest;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

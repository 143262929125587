import { useState, useCallback } from "react";
import Auth from "@aws-amplify/auth";
import { ConsoleLogger as Logger } from "@aws-amplify/core";

import { useAuthContext } from "./useAuthContext";
import { AuthState } from "../data/authState";

const logger = new Logger("useForgotPassword");

export const useForgotPassword = () => {
  const [delivery, setDelivery] = useState(null);

  const { handleStateChange } = useAuthContext();

  const submit = useCallback(
    async (email, code, password) => {
      try {
        await Auth.forgotPasswordSubmit(email, code, password);
        handleStateChange(AuthState.SIGN_IN, null);
      } catch (error) {
        logger.error(error);
        throw error;
      }
    },
    [handleStateChange]
  );

  const send = useCallback(async (email) => {
    try {
      const data = await Auth.forgotPassword(email);
      setDelivery(data.CodeDeliveryDetails);
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }, []);

  const goToForgotPassword = useCallback(
    () => handleStateChange(AuthState.FORGOT_PASSWORD, null),
    [handleStateChange]
  );

  return {
    delivery,
    submit,
    send,
    goToForgotPassword,
  };
};

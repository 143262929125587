import React from "react";
import { StudioCard } from "./StudioCard";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeList } from "react-window";
import {
  InitialFeed,
  LoadingSpinner,
  NoResultsFound,
} from "../../../components";

const renderStudioCard = ({ index, style }) => {
  return <StudioCard index={index} style={style} />;
};

export const StudioCardList = ({
  hasMore,
  itemsLength,
  fetchItems,
  isLoaded,
  isLoading,
}) => {
  const isItemLoaded = (index) => !hasMore || index < itemsLength;

  const itemCount = hasMore ? itemsLength + 1 : itemsLength;

  if (!isLoaded && !isLoading) {
    return <InitialFeed textKey="discover-page_search-studios-tagline" />;
  }

  if (!isLoaded && isLoading) {
    return <LoadingSpinner textKey="discover-page_loading-studios-tagline" />;
  }

  if (isLoaded && itemsLength === 0) {
    return (
      <NoResultsFound textKey="discover-page_no-results-studios-tagline" />
    );
  }

  return (
    <div className="flex flex-grow flex-col px-4">
      <AutoSizer>
        {({ width, height }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={fetchItems}
          >
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                height={height}
                width={width}
                itemCount={itemCount}
                itemSize={194}
                onItemsRendered={onItemsRendered}
                ref={ref}
              >
                {renderStudioCard}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </div>
  );
};

import React from "react";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import { useS3Image } from "../hooks";

export const S3Image = ({
  imgKey,
  alt,
  ImageProps,
  variant = "rect",
  intersectionOptions = {},
  onClick,
}) => {
  const { src, intersectionRef, loaded, onLoad } = useS3Image(
    imgKey,
    intersectionOptions
  );

  const skeletonClassNames = clsx(ImageProps?.className || "", {
    hidden: loaded,
  });

  const imageClassNames = clsx(ImageProps?.className || "", {
    hidden: !loaded,
  });

  if (!imgKey) {
    return (
      <Skeleton
        animation={false}
        className={ImageProps?.className}
        variant={variant}
        height="100%"
      />
    );
  }

  return (
    <div className="h-full" ref={intersectionRef}>
      <Skeleton
        height="100%"
        className={skeletonClassNames}
        variant={variant}
      />
      <img
        onClick={onClick}
        alt={alt}
        src={src}
        className={imageClassNames}
        onLoad={onLoad}
      />
    </div>
  );
};

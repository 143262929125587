import * as React from "react";

import { AuthProvider } from "./AuthProvider";
import { AuthRoute } from "./AuthRoute";
import { AuthState } from "./data/authState";
import { SignIn } from "./SignIn";
import { ConfirmSignIn } from "./ConfirmSignIn";
import { SignUp } from "./SignUp";
import { ConfirmSignUp } from "./ConfirmSignUp";
import { ForgotPassword } from "./ForgotPassword";
import { RequireNewPassword } from "./RequireNewPassword";
import { VerifyContact } from "./VerifyContact";
import { Onboarding } from "./Onboarding";
import { AuthLoading } from "./AuthLoading";

const defaultChildren = [
  {
    validAuthStates: [AuthState.LOADING],
    component: AuthLoading,
  },
  {
    validAuthStates: [AuthState.ONBOARDING],
    component: Onboarding,
  },
  {
    validAuthStates: [AuthState.SIGN_IN, AuthState.SIGN_OUT],
    component: SignIn,
  },
  {
    validAuthStates: [AuthState.CONFIRM_SIGN_IN],
    component: ConfirmSignIn,
  },
  {
    validAuthStates: [AuthState.SIGN_UP],
    component: SignUp,
  },
  {
    validAuthStates: [AuthState.CONFIRM_SIGN_UP],
    component: ConfirmSignUp,
  },
  {
    validAuthStates: [AuthState.FORGOT_PASSWORD],
    component: ForgotPassword,
  },
  {
    validAuthStates: [AuthState.REQUIRE_NEW_PASSWORD],
    component: RequireNewPassword,
  },
  {
    validAuthStates: [AuthState.VERIFY_CONTACT],
    component: VerifyContact,
  },
];

export const AuthRouter = ({
  hide = [],
  children,
  initialAuthState,
  onStateChange,
  ...authConfig
}) => {
  const renderChildren = defaultChildren
    .filter((item) => !hide.includes(item.component))
    .map((item, index) => (
      <AuthRoute
        key={`authenticator-children-${index}`}
        {...item}
        {...authConfig}
      />
    ));
  return (
    <AuthProvider
      initialAuthState={initialAuthState}
      onStateChange={onStateChange}
    >
      {renderChildren}
      {children}
    </AuthProvider>
  );
};

import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Divider } from "@material-ui/core";
import {
  ProfilePhoto,
  LikedToggle,
  SendMessage,
  SpecializationChip,
  PostsList,
} from "../../../components";
import { uploadArtistProfileImage } from "../../../api";
import { Specialization, priceMapping } from "../../../models";
import { useArtistPosts } from "../../../hooks";

export const ArtistProfile = ({ user, isOwner }) => {
  const { t } = useTranslation();
  const [artist, setArtist] = useState(user);
  const { items, fetchItems, hasMore, isLoading, isLoaded } = useArtistPosts(
    user.id
  );

  const handleProfilePhotoUpload = useCallback(
    async (file) => {
      const response = await uploadArtistProfileImage(artist.id, file);
      setArtist(response);
    },
    [artist]
  );

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  return (
    <article className="flex flex-col h-full">
      <div className="w-full flex flex-col mt-4 py-3 px-4">
        <div className="flex justify-end">
          <LikedToggle />
          <SendMessage />
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row">
            <ProfilePhoto
              imgKey={user.profilePic?.key}
              size="lg"
              onImageUpload={handleProfilePhotoUpload}
            />
            <div className="flex flex-col pl-3">
              <div className="font-bold text-lg">{user.fullName}</div>
              <p className="font-light">
                {(user.location &&
                  user.studio?.fullName &&
                  `${user.location} | ${user.studio?.fullName}`) ||
                  user.location ||
                  user.studio?.fullName}
              </p>
              <p className="font-bold">
                {(user.pricePoint && t(priceMapping[user.pricePoint])) ||
                  "$???"}
              </p>
            </div>
          </div>
        </div>
        <div>
          <p className="mt-8">{user.biography}</p>
          {user.specializations?.length > 0 && (
            <div className="flex justify-center mt-8 flex-wrap">
              {user.specializations.map((specialization) => {
                return (
                  <SpecializationChip
                    key={`spec-${specialization}`}
                    label={t(`${Specialization[specialization]}-label`)}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
      <Divider />
      <PostsList
        isLoaded={isLoaded}
        isLoading={isLoading}
        hasMore={hasMore}
        fetchItems={fetchItems}
        itemsLength={items.length}
      />
    </article>
  );
};

import * as React from "react";
import { useAuthContext } from "./hooks/useAuthContext";

export const AuthRoute = ({
  validAuthStates,
  component,
  children,
  ...authConfig
}) => {
  const { authState } = useAuthContext();

  const regExp = new RegExp(`(${authState}|\\*)`);

  const match = validAuthStates.some((validAuthStates) =>
    regExp.test(validAuthStates)
  );

  return match
    ? component
      ? React.createElement(component, authConfig)
      : children
      ? children(authConfig)
      : null
    : null;
};

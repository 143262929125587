import React from "react";
import { Tab as MuiTab } from "@material-ui/core";
import clsx from "clsx";

export const Tab = ({ value, isActive, label, ...props }) => {
  const className = clsx(
    "flex flex-1 justify-center items-center text-base uppercase font-bold",
    {
      "bg-black text-white": isActive,
      "border-solid border-1/2 border-ash": !isActive,
    }
  );
  return (
    <MuiTab value={value} className={className} label={label} {...props} />
  );
};

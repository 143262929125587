import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavigationHeader, PostsList } from "../../components";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ArtistCardList, useSearchArtists } from "./ArtistCardList";
import { StudioCardList, useSearchStudios } from "./StudioCardList";
import { FilterPanel } from "./FilterPanel";
import { useSearchPosts } from "../../hooks";

export const Discover = () => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState({
    location: null,
    specializations: [],
    price: null,
    keywords: [],
  });

  const [{ showArtists, showStudios, showPosts }, setShowPanel] = useState({
    showArtists: true,
    showStudios: false,
    showPosts: false,
  });

  const history = useHistory();

  const onSearch = useCallback(() => {
    history.push("/search");
  }, [history]);

  const artists = useSearchArtists(filter);
  const studios = useSearchStudios(filter);
  const posts = useSearchPosts(filter);

  const onSubmit = useCallback(() => {
    if (showArtists) {
      artists.reload();
    }
    if (showStudios) {
      studios.reload();
    }
    if (showPosts) {
      posts.reload();
    }
  }, [artists, posts, showArtists, showPosts, showStudios, studios]);

  return (
    <article className="flex flex-col h-full">
      <NavigationHeader
        title={t("discover-page_header")}
        altIcon={<SearchIcon className="text-black" />}
        onAltActionClick={onSearch}
        decorated={false}
      />
      <FilterPanel
        setFilter={setFilter}
        onSubmit={onSubmit}
        setShowPanel={setShowPanel}
        showArtists={showArtists}
        showStudios={showStudios}
        showPosts={showPosts}
        hasArtists={artists.items.length > 0}
        hasStudios={studios.items.length > 0}
        hasPosts={posts.items.length > 0}
      />
      <div className="mt-6 flex flex-grow">
        {showArtists && (
          <ArtistCardList
            isLoaded={artists.isLoaded}
            isLoading={artists.isLoading}
            hasMore={artists.hasMore}
            fetchItems={artists.fetchItems}
            itemsLength={artists.items.length}
          />
        )}
        {showStudios && (
          <StudioCardList
            isLoaded={studios.isLoaded}
            isLoading={studios.isLoading}
            hasMore={studios.hasMore}
            fetchItems={studios.fetchItems}
            itemsLength={studios.items.length}
          />
        )}
        {showPosts && (
          <PostsList
            isLoaded={posts.isLoaded}
            isLoading={posts.isLoading}
            hasMore={posts.hasMore}
            fetchItems={posts.fetchItems}
            itemsLength={posts.items.length}
          />
        )}
      </div>
    </article>
  );
};

import { useCallback, useRef, useState } from "react";
import { useIntersection } from "react-use";

const cdnUrl = process.env.REACT_APP_CDN_URL;

export const useS3Image = (imgKey, intersectionOptions = {}) => {
  const intersectionRef = useRef(null);

  const intersection = useIntersection(intersectionRef, intersectionOptions);

  const shouldLoad = intersection && intersection.isIntersecting;

  const url = `${cdnUrl}/${imgKey}`;

  const [loaded, setLoaded] = useState(false);

  const onLoad = useCallback(() => setLoaded(true), []);

  const src = loaded || shouldLoad ? url : undefined;

  return { src, intersectionRef, loaded, onLoad };
};

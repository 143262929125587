import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Tabs, NavigationHeader } from "../../components";

const tabs = [
  {
    label: "top-navigation_artists",
  },
  {
    label: "top-navigation_studios",
  },
  {
    label: "top-navigation_ink",
  },
];

export const Favs = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);

  const onSelectedTab = useCallback((tabIndex) => {
    setSelectedTab(tabIndex);
  }, []);

  return (
    <article className="flex flex-col h-screen">
      <NavigationHeader title={t("favs-page_header")} decorated={false} />
      <Tabs
        tabs={tabs}
        selectedTab={selectedTab}
        onSelectedTab={onSelectedTab}
      />
      <div className="mt-6 flex flex-grow">
        {selectedTab === 0 && <div />}
        {selectedTab === 1 && <div />}
        {selectedTab === 2 && <div />}
      </div>
    </article>
  );
};

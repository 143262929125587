import React, { useCallback, Fragment } from "react";
import { Divider, ListItem, ListItemText } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export const SearchItem = ({ item, type }) => {
  const history = useHistory();

  const handleItemSelected = useCallback(() => {
    const id = item.id;
    history.push(`/profile/${type}/${id}`);
  }, [history, item.id, type]);

  return (
    <Fragment>
      <Divider />
      <ListItem button onClick={() => handleItemSelected(item.id)}>
        <ListItemText className="text-black">{item.fullName}</ListItemText>
      </ListItem>
      <Divider />
    </Fragment>
  );
};

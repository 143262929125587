export const AuthState = {
  LOADING: "loading",
  ONBOARDING: "onboarding",
  SIGN_IN: "signIn",
  CONFIRM_SIGN_IN: "confirmSignIn",
  SIGN_UP: "signUp",
  SIGNED_UP: "signedUp",
  CONFIRM_SIGN_UP: "confirmSignUp",
  SIGN_OUT: "signOut",
  FORGOT_PASSWORD: "forgotPassword",
  REQUIRE_NEW_PASSWORD: "requireNewPassword",
  VERIFY_CONTACT: "verifyContact",
  SIGNED_IN: "signedIn",
  TOTP_SETUP: "totpSetup",
};

import { Auth } from "aws-amplify";
import { useAuthContext } from "./useAuthContext";
import { AuthState } from "../data/authState";

export const useFederationAuth = ({ provider }) => {
  const { handleStateChange } = useAuthContext();

  const signIn = async () => {
    try {
      await Auth.federatedSignIn({ provider });
    } catch (error) {
      handleStateChange(AuthState.SIGN_IN, null);
    }
  };

  const signOut = async () => {
    await Auth.signOut();
  };

  return {
    signIn,
    signOut,
  };
};

import { useCallback } from "react";
import { searchPosts } from "../api/PostApi";
import { useEventCallback } from "rxjs-hooks";
import { tap, concatMap, take, flatMap, takeUntil } from "rxjs/operators";
import { from, Subject } from "rxjs";
import { useRecoilState } from "recoil";
import {
  postsListState,
  postsNextTokenState,
  postsIsLoadingState,
  postsIsLoadedState,
  postsTotalState,
} from "../state";

export const useSearchPosts = ({
  location,
  specializations,
  price,
  keywords,
}) => {
  const [nextToken, setNextToken] = useRecoilState(postsNextTokenState);
  const [isLoading, setIsLoading] = useRecoilState(postsIsLoadingState);
  const [isLoaded, setIsLoaded] = useRecoilState(postsIsLoadedState);
  const [total, setTotal] = useRecoilState(postsTotalState);

  const [items, setItems] = useRecoilState(postsListState);

  const cancel$ = new Subject();

  const [fetchItems] = useEventCallback(
    (event$, _, input$) =>
      event$.pipe(
        concatMap(() =>
          input$.pipe(
            take(1),
            tap(() => setIsLoading(true)),
            flatMap(([location, specializations, price, keywords, nextToken]) =>
              from(
                searchPosts(
                  location,
                  specializations,
                  price,
                  keywords,
                  nextToken
                )
              ).pipe(takeUntil(cancel$))
            )
          )
        ),
        tap((response) => {
          setItems((state) => [...state, ...response.items]);
          setNextToken(response.nextToken);
          setTotal(response.total);
          setIsLoading(false);
          setIsLoaded(true);
        })
      ),
    [],
    [location, specializations, price, keywords, nextToken]
  );

  const hasMore = nextToken !== null && items.length < total;

  const reload = useCallback(() => {
    cancel$.next();
    setIsLoaded(false);
    setIsLoading(false);
    setItems([]);
    setNextToken("");
    fetchItems();
  }, [cancel$, fetchItems, setIsLoaded, setIsLoading, setItems, setNextToken]);

  return {
    items,
    fetchItems,
    hasMore,
    isLoading,
    isLoaded,
    reload,
  };
};

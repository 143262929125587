/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteLikeByOwner = /* GraphQL */ `
  mutation DeleteLikeByOwner($ownerID: ID, $targetItemID: ID) {
    deleteLikeByOwner(ownerID: $ownerID, targetItemID: $targetItemID) {
      id
      ownerID
      targetItemID
      createdAt
      updatedAt
    }
  }
`;
export const confirmCollaborationRequest = /* GraphQL */ `
  mutation ConfirmCollaborationRequest(
    $input: ConfirmCollaborationRequestInput!
    $condition: ModelCollaborationRequestConditionInput
  ) {
    confirmCollaborationRequest(input: $input, condition: $condition) {
      id
      ownerID
      artistID
      studioID
      artist {
        id
        owner
        profilePic {
          bucket
          region
          key
          visibility
        }
        fullName
        location
        biography
        hourlyRate
        pricePoint
        specializations
        posts {
          nextToken
        }
        studioID
        studio {
          id
          owner
          fullName
          location
          biography
          hourlyRate
          pricePoint
          specializations
          likedByViewer
          createdAt
          updatedAt
        }
        likedByViewer
        collaborationRequests {
          nextToken
        }
        createdAt
        updatedAt
      }
      studio {
        id
        owner
        profilePic {
          bucket
          region
          key
          visibility
        }
        coverPic {
          bucket
          region
          key
          visibility
        }
        fullName
        location
        biography
        artists {
          nextToken
        }
        hourlyRate
        pricePoint
        specializations
        likedByViewer
        collaborationRequests {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      title
      image {
        bucket
        region
        key
        visibility
      }
      keywords
      specializations
      location
      pricePoint
      artistID
      artist {
        id
        owner
        profilePic {
          bucket
          region
          key
          visibility
        }
        fullName
        location
        biography
        hourlyRate
        pricePoint
        specializations
        posts {
          nextToken
        }
        studioID
        studio {
          id
          owner
          fullName
          location
          biography
          hourlyRate
          pricePoint
          specializations
          likedByViewer
          createdAt
          updatedAt
        }
        likedByViewer
        collaborationRequests {
          nextToken
        }
        createdAt
        updatedAt
      }
      likedByViewer
      createdAt
      updatedAt
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      title
      image {
        bucket
        region
        key
        visibility
      }
      keywords
      specializations
      location
      pricePoint
      artistID
      artist {
        id
        owner
        profilePic {
          bucket
          region
          key
          visibility
        }
        fullName
        location
        biography
        hourlyRate
        pricePoint
        specializations
        posts {
          nextToken
        }
        studioID
        studio {
          id
          owner
          fullName
          location
          biography
          hourlyRate
          pricePoint
          specializations
          likedByViewer
          createdAt
          updatedAt
        }
        likedByViewer
        collaborationRequests {
          nextToken
        }
        createdAt
        updatedAt
      }
      likedByViewer
      createdAt
      updatedAt
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      title
      image {
        bucket
        region
        key
        visibility
      }
      keywords
      specializations
      location
      pricePoint
      artistID
      artist {
        id
        owner
        profilePic {
          bucket
          region
          key
          visibility
        }
        fullName
        location
        biography
        hourlyRate
        pricePoint
        specializations
        posts {
          nextToken
        }
        studioID
        studio {
          id
          owner
          fullName
          location
          biography
          hourlyRate
          pricePoint
          specializations
          likedByViewer
          createdAt
          updatedAt
        }
        likedByViewer
        collaborationRequests {
          nextToken
        }
        createdAt
        updatedAt
      }
      likedByViewer
      createdAt
      updatedAt
    }
  }
`;
export const createPerson = /* GraphQL */ `
  mutation CreatePerson(
    $input: CreatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    createPerson(input: $input, condition: $condition) {
      id
      profilePic {
        bucket
        region
        key
        visibility
      }
      coverPic {
        bucket
        region
        key
        visibility
      }
      fullName
      location
      biography
      myNextTat
      moodboards {
        id
        title
        images {
          bucket
          region
          key
          visibility
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePerson = /* GraphQL */ `
  mutation UpdatePerson(
    $input: UpdatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    updatePerson(input: $input, condition: $condition) {
      id
      profilePic {
        bucket
        region
        key
        visibility
      }
      coverPic {
        bucket
        region
        key
        visibility
      }
      fullName
      location
      biography
      myNextTat
      moodboards {
        id
        title
        images {
          bucket
          region
          key
          visibility
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePerson = /* GraphQL */ `
  mutation DeletePerson(
    $input: DeletePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    deletePerson(input: $input, condition: $condition) {
      id
      profilePic {
        bucket
        region
        key
        visibility
      }
      coverPic {
        bucket
        region
        key
        visibility
      }
      fullName
      location
      biography
      myNextTat
      moodboards {
        id
        title
        images {
          bucket
          region
          key
          visibility
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createArtist = /* GraphQL */ `
  mutation CreateArtist(
    $input: CreateArtistInput!
    $condition: ModelArtistConditionInput
  ) {
    createArtist(input: $input, condition: $condition) {
      id
      owner
      profilePic {
        bucket
        region
        key
        visibility
      }
      fullName
      location
      biography
      hourlyRate
      pricePoint
      specializations
      posts {
        items {
          id
          title
          keywords
          specializations
          location
          pricePoint
          artistID
          likedByViewer
          createdAt
          updatedAt
        }
        nextToken
      }
      studioID
      studio {
        id
        owner
        profilePic {
          bucket
          region
          key
          visibility
        }
        coverPic {
          bucket
          region
          key
          visibility
        }
        fullName
        location
        biography
        artists {
          nextToken
        }
        hourlyRate
        pricePoint
        specializations
        likedByViewer
        collaborationRequests {
          nextToken
        }
        createdAt
        updatedAt
      }
      likedByViewer
      collaborationRequests {
        items {
          id
          ownerID
          artistID
          studioID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateArtist = /* GraphQL */ `
  mutation UpdateArtist(
    $input: UpdateArtistInput!
    $condition: ModelArtistConditionInput
  ) {
    updateArtist(input: $input, condition: $condition) {
      id
      owner
      profilePic {
        bucket
        region
        key
        visibility
      }
      fullName
      location
      biography
      hourlyRate
      pricePoint
      specializations
      posts {
        items {
          id
          title
          keywords
          specializations
          location
          pricePoint
          artistID
          likedByViewer
          createdAt
          updatedAt
        }
        nextToken
      }
      studioID
      studio {
        id
        owner
        profilePic {
          bucket
          region
          key
          visibility
        }
        coverPic {
          bucket
          region
          key
          visibility
        }
        fullName
        location
        biography
        artists {
          nextToken
        }
        hourlyRate
        pricePoint
        specializations
        likedByViewer
        collaborationRequests {
          nextToken
        }
        createdAt
        updatedAt
      }
      likedByViewer
      collaborationRequests {
        items {
          id
          ownerID
          artistID
          studioID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteArtist = /* GraphQL */ `
  mutation DeleteArtist(
    $input: DeleteArtistInput!
    $condition: ModelArtistConditionInput
  ) {
    deleteArtist(input: $input, condition: $condition) {
      id
      owner
      profilePic {
        bucket
        region
        key
        visibility
      }
      fullName
      location
      biography
      hourlyRate
      pricePoint
      specializations
      posts {
        items {
          id
          title
          keywords
          specializations
          location
          pricePoint
          artistID
          likedByViewer
          createdAt
          updatedAt
        }
        nextToken
      }
      studioID
      studio {
        id
        owner
        profilePic {
          bucket
          region
          key
          visibility
        }
        coverPic {
          bucket
          region
          key
          visibility
        }
        fullName
        location
        biography
        artists {
          nextToken
        }
        hourlyRate
        pricePoint
        specializations
        likedByViewer
        collaborationRequests {
          nextToken
        }
        createdAt
        updatedAt
      }
      likedByViewer
      collaborationRequests {
        items {
          id
          ownerID
          artistID
          studioID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createStudio = /* GraphQL */ `
  mutation CreateStudio(
    $input: CreateStudioInput!
    $condition: ModelStudioConditionInput
  ) {
    createStudio(input: $input, condition: $condition) {
      id
      owner
      profilePic {
        bucket
        region
        key
        visibility
      }
      coverPic {
        bucket
        region
        key
        visibility
      }
      fullName
      location
      biography
      artists {
        items {
          id
          owner
          fullName
          location
          biography
          hourlyRate
          pricePoint
          specializations
          studioID
          likedByViewer
          createdAt
          updatedAt
        }
        nextToken
      }
      hourlyRate
      pricePoint
      specializations
      likedByViewer
      collaborationRequests {
        items {
          id
          ownerID
          artistID
          studioID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateStudio = /* GraphQL */ `
  mutation UpdateStudio(
    $input: UpdateStudioInput!
    $condition: ModelStudioConditionInput
  ) {
    updateStudio(input: $input, condition: $condition) {
      id
      owner
      profilePic {
        bucket
        region
        key
        visibility
      }
      coverPic {
        bucket
        region
        key
        visibility
      }
      fullName
      location
      biography
      artists {
        items {
          id
          owner
          fullName
          location
          biography
          hourlyRate
          pricePoint
          specializations
          studioID
          likedByViewer
          createdAt
          updatedAt
        }
        nextToken
      }
      hourlyRate
      pricePoint
      specializations
      likedByViewer
      collaborationRequests {
        items {
          id
          ownerID
          artistID
          studioID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteStudio = /* GraphQL */ `
  mutation DeleteStudio(
    $input: DeleteStudioInput!
    $condition: ModelStudioConditionInput
  ) {
    deleteStudio(input: $input, condition: $condition) {
      id
      owner
      profilePic {
        bucket
        region
        key
        visibility
      }
      coverPic {
        bucket
        region
        key
        visibility
      }
      fullName
      location
      biography
      artists {
        items {
          id
          owner
          fullName
          location
          biography
          hourlyRate
          pricePoint
          specializations
          studioID
          likedByViewer
          createdAt
          updatedAt
        }
        nextToken
      }
      hourlyRate
      pricePoint
      specializations
      likedByViewer
      collaborationRequests {
        items {
          id
          ownerID
          artistID
          studioID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createLike = /* GraphQL */ `
  mutation CreateLike(
    $input: CreateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    createLike(input: $input, condition: $condition) {
      id
      ownerID
      targetItemID
      createdAt
      updatedAt
    }
  }
`;
export const updateLike = /* GraphQL */ `
  mutation UpdateLike(
    $input: UpdateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    updateLike(input: $input, condition: $condition) {
      id
      ownerID
      targetItemID
      createdAt
      updatedAt
    }
  }
`;
export const deleteLike = /* GraphQL */ `
  mutation DeleteLike(
    $input: DeleteLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    deleteLike(input: $input, condition: $condition) {
      id
      ownerID
      targetItemID
      createdAt
      updatedAt
    }
  }
`;
export const createCollaborationRequest = /* GraphQL */ `
  mutation CreateCollaborationRequest(
    $input: CreateCollaborationRequestInput!
    $condition: ModelCollaborationRequestConditionInput
  ) {
    createCollaborationRequest(input: $input, condition: $condition) {
      id
      ownerID
      artistID
      studioID
      artist {
        id
        owner
        profilePic {
          bucket
          region
          key
          visibility
        }
        fullName
        location
        biography
        hourlyRate
        pricePoint
        specializations
        posts {
          nextToken
        }
        studioID
        studio {
          id
          owner
          fullName
          location
          biography
          hourlyRate
          pricePoint
          specializations
          likedByViewer
          createdAt
          updatedAt
        }
        likedByViewer
        collaborationRequests {
          nextToken
        }
        createdAt
        updatedAt
      }
      studio {
        id
        owner
        profilePic {
          bucket
          region
          key
          visibility
        }
        coverPic {
          bucket
          region
          key
          visibility
        }
        fullName
        location
        biography
        artists {
          nextToken
        }
        hourlyRate
        pricePoint
        specializations
        likedByViewer
        collaborationRequests {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCollaborationRequest = /* GraphQL */ `
  mutation UpdateCollaborationRequest(
    $input: UpdateCollaborationRequestInput!
    $condition: ModelCollaborationRequestConditionInput
  ) {
    updateCollaborationRequest(input: $input, condition: $condition) {
      id
      ownerID
      artistID
      studioID
      artist {
        id
        owner
        profilePic {
          bucket
          region
          key
          visibility
        }
        fullName
        location
        biography
        hourlyRate
        pricePoint
        specializations
        posts {
          nextToken
        }
        studioID
        studio {
          id
          owner
          fullName
          location
          biography
          hourlyRate
          pricePoint
          specializations
          likedByViewer
          createdAt
          updatedAt
        }
        likedByViewer
        collaborationRequests {
          nextToken
        }
        createdAt
        updatedAt
      }
      studio {
        id
        owner
        profilePic {
          bucket
          region
          key
          visibility
        }
        coverPic {
          bucket
          region
          key
          visibility
        }
        fullName
        location
        biography
        artists {
          nextToken
        }
        hourlyRate
        pricePoint
        specializations
        likedByViewer
        collaborationRequests {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCollaborationRequest = /* GraphQL */ `
  mutation DeleteCollaborationRequest(
    $input: DeleteCollaborationRequestInput!
    $condition: ModelCollaborationRequestConditionInput
  ) {
    deleteCollaborationRequest(input: $input, condition: $condition) {
      id
      ownerID
      artistID
      studioID
      artist {
        id
        owner
        profilePic {
          bucket
          region
          key
          visibility
        }
        fullName
        location
        biography
        hourlyRate
        pricePoint
        specializations
        posts {
          nextToken
        }
        studioID
        studio {
          id
          owner
          fullName
          location
          biography
          hourlyRate
          pricePoint
          specializations
          likedByViewer
          createdAt
          updatedAt
        }
        likedByViewer
        collaborationRequests {
          nextToken
        }
        createdAt
        updatedAt
      }
      studio {
        id
        owner
        profilePic {
          bucket
          region
          key
          visibility
        }
        coverPic {
          bucket
          region
          key
          visibility
        }
        fullName
        location
        biography
        artists {
          nextToken
        }
        hourlyRate
        pricePoint
        specializations
        likedByViewer
        collaborationRequests {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

import React, { useCallback, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { List, ListItem, ListItemText, Divider } from "@material-ui/core";
import { EditProfileItem } from "./EditProfileItem";
import {
  ProfileNameItem,
  BioItem,
  MyNextTatItem,
  PasswordItem,
  DeleteAccountItem,
} from "./items";
import { NavigationHeader } from "../../components";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import { LocationItem } from "./items/LocationItem";
import { useEditProfile } from "../../hooks";
import { UserType } from "../../models";
import { ArtistConnectionsItem } from "./items/ArtistConnectionsItem";
import { StudioConnectionsItem } from "./items/StudioConnectionsItem";

export const EditProfile = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id, type } = useParams();
  const nameRef = useRef(null);
  const passRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const {
    user,
    artists,
    studios,
    fetchArtists,
    fetchStudios,
    updateFullName,
    updateBio,
    updateMyNextTat,
    updateLocation,
    confirmCollaborationRequest,
    createCollaborationRequestByArtist,
    createCollaborationRequestByStudio,
    removeConnection,
    removeCollaborationRequest,
  } = useEditProfile(type, id);

  const handleChange = useCallback(
    (panel) => (_, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    },
    []
  );

  const goToAbout = useCallback(() => history.push("/about"), [history]);

  const onLogout = useCallback(() => Auth.signOut(), []);

  const handleNameUpdate = useCallback(
    async (fullName) => {
      await updateFullName(fullName);
      setExpanded(false);
    },
    [updateFullName]
  );

  const handleBioUpdate = useCallback(
    async (bio) => {
      await updateBio(bio);
      setExpanded(false);
    },
    [updateBio]
  );

  const handleMyNextTatUpdate = useCallback(
    async (myNextTat) => {
      await updateMyNextTat(myNextTat);
      setExpanded(false);
    },
    [updateMyNextTat]
  );

  const handleLocationUpdate = useCallback(
    async (location) => {
      await updateLocation(location);
      setExpanded(false);
    },
    [updateLocation]
  );

  const handlePasswordUpdate = useCallback(
    async (currentPassword, password) => {
      try {
        const authUser = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(authUser, currentPassword, password);
        setExpanded(false);
      } catch (err) {
        passRef.current.setFormError(err);
      }
    },
    [passRef]
  );

  const handleRemoveConnection = useCallback(
    async (artistId) => {
      await removeConnection(artistId);
      setExpanded(false);
    },
    [removeConnection]
  );

  const handleConfirmRequest = useCallback(
    async (requestId) => {
      await confirmCollaborationRequest(requestId);
      setExpanded(false);
    },
    [confirmCollaborationRequest]
  );

  const handleRemoveRequest = useCallback(
    async (requestId) => {
      await removeCollaborationRequest(requestId);
      setExpanded(false);
    },
    [removeCollaborationRequest]
  );

  const handleConnectByArtist = useCallback(
    async (requesteeId) => {
      await createCollaborationRequestByArtist(id, requesteeId);
      setExpanded(false);
    },
    [id, createCollaborationRequestByArtist]
  );

  const handleConnectByStudio = useCallback(
    async (requesteeId) => {
      await createCollaborationRequestByStudio(id, requesteeId);
      setExpanded(false);
    },
    [id, createCollaborationRequestByStudio]
  );

  return (
    <article>
      <NavigationHeader
        title={t("edit-profile-page_title")}
        icon={<ArrowLeft />}
        onActionClick={history.goBack}
      />
      <div>
        <EditProfileItem
          id="name"
          title={t("edit-profile-page_name")}
          expanded={expanded === "name-panel"}
          onExpanded={handleChange("name-panel")}
        >
          <ProfileNameItem
            fullName={user?.fullName}
            onSave={handleNameUpdate}
            ref={nameRef}
          />
        </EditProfileItem>
        <EditProfileItem
          id="location"
          title={t("edit-profile-page_location")}
          expanded={expanded === "location-panel"}
          onExpanded={handleChange("location-panel")}
        >
          <LocationItem
            location={user?.location}
            onSave={handleLocationUpdate}
          />
        </EditProfileItem>
        {type === UserType.Studio && (
          <EditProfileItem
            id="artists"
            title={t("edit-profile-page_artists")}
            expanded={expanded === "artists-panel"}
            onExpanded={handleChange("artists-panel")}
            detailsStyle="p-0"
          >
            <ArtistConnectionsItem
              artists={artists}
              studio={user}
              onSearch={fetchArtists}
              onConfirmRequest={handleConfirmRequest}
              onRemoveConnection={handleRemoveConnection}
              onConnect={handleConnectByStudio}
              onRemoveRequest={handleRemoveRequest}
            />
          </EditProfileItem>
        )}
        {type === UserType.Artist && (
          <EditProfileItem
            id="studio"
            title={t("edit-profile-page_studio")}
            expanded={expanded === "studio-panel"}
            onExpanded={handleChange("studio-panel")}
            detailsStyle="p-0"
          >
            <StudioConnectionsItem
              studios={studios}
              artist={user}
              onSearch={fetchStudios}
              onConfirmRequest={handleConfirmRequest}
              onRemoveConnection={handleRemoveConnection}
              onConnect={handleConnectByArtist}
              onRemoveRequest={handleRemoveRequest}
            />
          </EditProfileItem>
        )}
        <EditProfileItem
          id="bio"
          title={t("edit-profile-page_bio")}
          expanded={expanded === "bio-panel"}
          onExpanded={handleChange("bio-panel")}
        >
          <BioItem bio={user?.biography} onSave={handleBioUpdate} />
        </EditProfileItem>
        {type === UserType.Person && (
          <EditProfileItem
            id="my-next-tat"
            title={t("edit-profile-page_my-next-tat")}
            expanded={expanded === "my-next-tat-panel"}
            onExpanded={handleChange("my-next-tat-panel")}
          >
            <MyNextTatItem
              text={user?.myNextTat}
              onSave={handleMyNextTatUpdate}
            />
          </EditProfileItem>
        )}
        <EditProfileItem
          id="password"
          title={t("edit-profile-page_password")}
          expanded={expanded === "password-panel"}
          onExpanded={handleChange("password-panel")}
        >
          <PasswordItem onSave={handlePasswordUpdate} ref={passRef} />
        </EditProfileItem>
        <EditProfileItem
          id="delete-account"
          title={t("edit-profile-page_delete-account")}
          expanded={expanded === "delete-account-panel"}
          onExpanded={handleChange("delete-account-panel")}
        >
          <DeleteAccountItem onDelete={() => console.log("deleted")} />
        </EditProfileItem>
      </div>
      <Divider className="bg-ash" />
      <List disablePadding={true}>
        <ListItem button onClick={onLogout}>
          <ListItemText className="uppercase text-black">
            {t("edit-profile-page_logout")}
          </ListItemText>
        </ListItem>
        <Divider />
      </List>
      <List disablePadding={true}>
        <ListItem button onClick={goToAbout}>
          <ListItemText className="uppercase text-black">
            {t("edit-profile-page_about")}
          </ListItemText>
        </ListItem>
        <Divider className="bg-ash" />
      </List>
    </article>
  );
};

import React, { useMemo } from "react";
import { PostPreview } from "../PostPreview";
import { useRecoilValue } from "recoil";
import { postsListState } from "../../state";

export const PostItem = ({ index }) => {
  const intersectionOptions = useMemo(
    () => ({
      root: document.querySelector("#layout-scroll"),
      rootMargin: "30% 0px", // prefetch post on scroll
    }),
    []
  );

  const posts = useRecoilValue(postsListState);

  const post = posts[index];

  return (
    <div className="w-1/3 p-1">
      <div className="flex items-center overflow-hidden rounded-xl h-full">
        <PostPreview
          id={post?.id}
          alt={post?.id}
          imgKey={post?.image.key}
          intersectionOptions={intersectionOptions}
        />
      </div>
    </div>
  );
};

import React from "react";
import { Button } from "@material-ui/core";
import { useFederationAuth } from "./hooks/useFederationAuth";
import { ReactComponent as GoogleIcon } from "../assets/icons/google.svg";
import { ReactComponent as FacebookIcon } from "../assets/icons/facebook.svg";

export const FederatedSignIn = ({ text, provider = "Google" }) => {
  const { signIn } = useFederationAuth({ provider });

  if (provider === "Google") {
    return (
      <Button
        type="button"
        onClick={signIn}
        variant="outlined"
        fullWidth={true}
      >
        <GoogleIcon className="mr-4" /> {text}
      </Button>
    );
  }

  if (provider === "Facebook") {
    return (
      <Button
        type="button"
        onClick={signIn}
        variant="outlined"
        fullWidth={true}
      >
        <FacebookIcon className="mr-4" /> {text}
      </Button>
    );
  }

  return null;
};

import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { TextField, Button } from "@material-ui/core";
import { useErrors } from "../../../hooks";
import { FormError } from "../../../components";

export const MyNextTatItem = ({ myNextTat, onSave }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, errors, reset } = useForm();
  const { hasError, getErrorMessage } = useErrors(errors);
  const [formError, setFormError] = useState(null);

  const onSubmit = useCallback(
    async ({ myNextTat: myNextTatToSave }) => {
      try {
        onSave(myNextTatToSave);
      } catch (error) {
        setFormError(error);
      }
    },
    [onSave]
  );

  useEffect(() => {
    reset({ myNextTat });
  }, [reset, myNextTat]);

  return (
    <form
      className="w-full flex flex-col items-center"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <div className="mt-5 w-full">
        <Controller
          as={TextField}
          label={t("edit-profile-page_my-next-tat")}
          error={hasError("myNextTat")}
          helperText={getErrorMessage("myNextTat")}
          type="text"
          variant="outlined"
          className="w-full"
          name="myNextTat"
          control={control}
          multiline={true}
          inputProps={{ maxLength: 150 }}
          defaultValue=""
        />
      </div>
      <FormError error={formError} />
      <div className="flex justify-center mt-5 w-full">
        <Button type="submit" variant="contained" color="primary">
          {t("edit-profile-page_save")}
        </Button>
      </div>
    </form>
  );
};

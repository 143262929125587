import React from "react";
import { useHistory } from "react-router-dom";
import { List, ListItem, ListItemText, Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { NavigationHeader } from "../../components";
import { ReactComponent as Logo } from "../../assets/images/tattd_logo.svg";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";

const fillBlack = { fill: "black" };

export const About = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <article>
      <NavigationHeader
        title={t("about-page_header")}
        icon={<ArrowLeft />}
        onActionClick={history.goBack}
      />
      <div className="mt-8 mx-4 mb-6">
        <p className="text-graphite mb-4">{t("about-page_rate-description")}</p>
        <p className="text-graphite">
          {t("about-page_rate-description-additional")}
        </p>
      </div>
      <List disablePadding={true}>
        <Divider />
        <ListItem
          button
          component="a"
          target="_blank"
          rel="noopener"
          href="https://google.com"
        >
          <ListItemText className="uppercase text-black">
            {t("about-page_terms-of-service-item")}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem
          button
          component="a"
          target="_blank"
          rel="noopener"
          href="https://google.com"
        >
          <ListItemText className="uppercase text-black">
            {t("about-page_privacy-policy-item")}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem
          button
          component="a"
          target="_blank"
          rel="noopener"
          href="https://google.com"
        >
          <ListItemText className="uppercase text-black">
            {t("about-page_contact-item")}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem
          button
          component="a"
          target="_blank"
          rel="noopener"
          href="https://google.com"
        >
          <ListItemText className="uppercase text-black">
            {t("about-page_rate-app-item")}
          </ListItemText>
        </ListItem>
        <Divider />
      </List>
      <Logo className="mx-auto mt-12 h-32" style={fillBlack} />
      <p className="mt-8 text-center">v1.0</p>
    </article>
  );
};

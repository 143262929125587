import React from "react";
import { ReactComponent as SkullAndBonesIcon } from "../assets/icons/skull-and-bones.svg";
import { useTranslation } from "react-i18next";

export const NoResultsFound = ({ textKey }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center w-full">
      <SkullAndBonesIcon />
      <p className="mt-2 text-xl font-bold">{t(textKey)}</p>
    </div>
  );
};

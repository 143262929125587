import { useEffect, useState } from "react";
import { from } from "rxjs";
import { UserType } from "../models";
import { getStudioProfile, getArtistProfile, getPersonProfile } from "../api";

const getFetchEntity = (type) => {
  if (type === UserType.Person) {
    return getPersonProfile;
  }
  if (type === UserType.Artist) {
    return getArtistProfile;
  }
  if (type === UserType.Studio) {
    return getStudioProfile;
  }
  return () => {};
};

export const useProfile = (type, id) => {
  const [user, setUser] = useState();

  useEffect(() => {
    const subscription = from(getFetchEntity(type)(id)).subscribe(setUser);

    return () => subscription.unsubscribe();
  }, [type, id]);

  return {
    user,
    setUser,
  };
};

import React, { useState, useCallback, useEffect } from "react";
import { LocationFilter } from "../../components/LocationFilter";
import { FilterByButtons } from "./FilterByButtons";
import { SpecializationsFilter } from "./SpecializationsFilter";
import { PricesFilter } from "./PricesFilter";
import { KeywordsFilter } from "./KeywordsFilter";
import { FilterName } from "./FilterName";
import { Button, Link } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { motion, useAnimation } from "framer-motion";
import { Tabs } from "../../components";

const tabs = [
  {
    label: "top-navigation_artists",
  },
  {
    label: "top-navigation_studios",
  },
  {
    label: "top-navigation_ink",
  },
];

export const FilterPanel = ({
  setFilter,
  onSubmit,
  setShowPanel,
  showArtists,
  showStudios,
  showPosts,
  hasArtists,
  hasStudios,
  hasPosts,
}) => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState(0);

  const [filterName, setFilterName] = useState("");

  const [specializations, setSpecializations] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [price, setPrice] = useState(null);
  const [city, setCity] = useState(null);

  const [isOpen, setIsOpen] = useState(true);

  const controls = useAnimation();

  const handleSetSpecializations = useCallback((value) => {
    setSpecializations(value);
    setIsOpen(true);
  }, []);

  const handleSetKeywords = useCallback((value) => {
    setKeywords(value);
    setIsOpen(true);
  }, []);

  const handleSetPrice = useCallback((value) => {
    setPrice(value);
    setIsOpen(true);
  }, []);

  const handleSetCity = useCallback((value) => {
    setCity(value);
    setIsOpen(true);
  }, []);

  const onReset = useCallback(() => {
    handleSetCity(null);
    handleSetSpecializations([]);
    handleSetPrice(null);
    handleSetKeywords([]);
  }, [
    handleSetCity,
    handleSetKeywords,
    handleSetPrice,
    handleSetSpecializations,
  ]);

  const resetFilter = useCallback(
    () =>
      setFilter({
        location: city,
        specializations,
        price: price?.value,
        keywords,
      }),
    [city, keywords, price, setFilter, specializations]
  );

  const handleOnSubmit = useCallback(() => {
    setIsOpen(false);
    setFilterName("");
    resetFilter();
    setTimeout(() => onSubmit(), 0);
  }, [onSubmit, resetFilter]);

  useEffect(() => {
    const animation = isOpen
      ? { height: "auto", opacity: 1, duration: 1 }
      : { height: 0, opacity: 0, duration: 0.6 };
    controls.start(animation);
  }, [controls, isOpen]);

  const onSelectedTab = useCallback(
    (tabIndex) => {
      setSelectedTab(tabIndex);
      const shouldShowArtists = tabIndex === 0;
      const shouldShowStudios = tabIndex === 1;
      const shouldShowPosts = tabIndex === 2;
      setShowPanel({
        showArtists: shouldShowArtists,
        showStudios: shouldShowStudios,
        showPosts: shouldShowPosts,
      });
      setIsOpen(
        (shouldShowArtists && !hasArtists) ||
          (shouldShowStudios && !hasStudios) ||
          (shouldShowPosts && !hasPosts)
      );
    },
    [hasArtists, hasPosts, hasStudios, setShowPanel]
  );

  return (
    <article>
      <Tabs
        tabs={tabs}
        selectedTab={selectedTab}
        onSelectedTab={onSelectedTab}
      />
      <div className="px-4">
        <LocationFilter value={city} onChange={handleSetCity} />
        <FilterByButtons
          activeFilterName={filterName}
          onChange={setFilterName}
          specializations={specializations}
          price={price}
          keywords={keywords}
          disableKeywords={showStudios}
        />
      </div>
      <motion.div className="overflow-hidden">
        {filterName === FilterName.Specialization && (
          <SpecializationsFilter
            activeSpecializations={specializations}
            onChange={handleSetSpecializations}
          />
        )}
        {filterName === FilterName.Price && (
          <PricesFilter activePrice={price} onChange={handleSetPrice} />
        )}
        {filterName === FilterName.Keywords && !showStudios && (
          <KeywordsFilter
            activeKeywords={keywords}
            onChange={handleSetKeywords}
          />
        )}
      </motion.div>
      <motion.div className="overflow-hidden" animate={controls}>
        <div className="flex justify-between px-4 py-2 bg-wine mt-6">
          <Link
            className="flex items-center text-white cursor-pointer"
            onClick={onReset}
          >
            {t("discover-page_reset-button")}
          </Link>
          <Button
            className="px-8 py-2 normal-case"
            type="button"
            variant="contained"
            color="secondary"
            onClick={handleOnSubmit}
          >
            {t("discover-page_submit-button")}
          </Button>
        </div>
      </motion.div>
    </article>
  );
};

import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export const EditProfileItem = ({
  id,
  title,
  expanded,
  onExpanded,
  children,
  detailsStyle,
}) => {
  return (
    <Accordion expanded={expanded} onChange={onExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <span className="uppercase">{title}</span>
      </AccordionSummary>
      <AccordionDetails className={detailsStyle}>{children}</AccordionDetails>
    </Accordion>
  );
};

import { useEffect, useState, useCallback } from "react";
import Auth from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import { AuthState } from "../data/authState";
import { combineLatest, timer, from, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";

const minLoading = 1000;

export const useAuth = ({
  initialAuthState = AuthState.LOADING,
  onStateChange,
}) => {
  const [state, setState] = useState({
    authState: initialAuthState,
    authData: null,
  });

  const handleStateChange = useCallback(
    (authState, authData) => {
      if (authState === AuthState.SIGN_OUT) {
        authState = AuthState.SIGN_IN;
      }

      setState((prev) => {
        const newState = onStateChange
          ? onStateChange(prev, { authState, authData })
          : { authState, authData };
        return {
          ...prev,
          ...newState,
        };
      });
    },
    [onStateChange]
  );

  useEffect(() => {
    const checkUser = async () => {
      combineLatest(
        from(Auth.currentAuthenticatedUser()).pipe(
          catchError((error) =>
            timer(minLoading).pipe(switchMap(throwError(error)))
          )
        ),
        timer(minLoading)
      ).subscribe(
        ([user]) => handleStateChange(AuthState.SIGNED_IN, user),
        () => handleStateChange(AuthState.ONBOARDING, null)
      );
    };
    checkUser();
  }, [handleStateChange]);

  useEffect(() => {
    const handleAuthCapsule = (capsule) => {
      const { payload } = capsule;

      switch (payload.event) {
        case "parsingUrl_failure":
        case "signOut":
          handleStateChange(AuthState.SIGN_IN, null);
          break;
        default:
          //TODO
          break;
      }
    };
    Hub.listen("auth", handleAuthCapsule);

    return () => {
      Hub.remove("auth", handleAuthCapsule);
    };
  });

  return {
    ...state,
    handleStateChange,
  };
};

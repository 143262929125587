import React, { useCallback } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { LocationInput } from "./LocationInput";
import { cities } from "../../models";

const renderInput = (params) => <LocationInput {...params} />;

export const LocationFilter = ({ value, onChange, size = "small" }) => {
  const handleOnChange = useCallback((_, newValue) => onChange(newValue), [
    onChange,
  ]);

  return (
    <Autocomplete
      size={size}
      className="mt-3"
      value={value}
      onChange={handleOnChange}
      options={cities}
      renderInput={renderInput}
    />
  );
};

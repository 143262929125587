import { atom } from "recoil";

export const postsListState = atom({
  key: "postsListState",
  default: [],
});

export const postsNextTokenState = atom({
  key: "postsNextTokenState",
  default: "",
});

export const postsIsLoadingState = atom({
  key: "postsIsLoadingState",
  default: false,
});

export const postsIsLoadedState = atom({
  key: "postsIsLoadedState",
  default: false,
});

export const postsTotalState = atom({
  key: "postsTotalState",
  default: 0,
});

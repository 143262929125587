import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, InputAdornment, Chip, TextField } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useErrors } from "../../hooks";

const inputProps = { className: "py-2" };

const preventDefault = (event) => {
  event.preventDefault();
};

export const KeywordsFilter = ({ activeKeywords, onChange }) => {
  const { t } = useTranslation();

  const { register, handleSubmit, errors, reset } = useForm();

  const { hasError, getErrorMessage } = useErrors(errors);

  const onSubmit = useCallback(
    (formValues) => {
      if (activeKeywords.indexOf(formValues.keyword) === -1) {
        onChange((state) => [...state, formValues.keyword]);
        reset();
      }
    },
    [onChange, activeKeywords, reset]
  );

  const keywordInputProps = useMemo(
    () => ({
      startAdornment: <InputAdornment position="start">#</InputAdornment>,
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            color="primary"
            type="submit"
            aria-label={t("discover-page-img-add-keyword-alt")}
            onMouseDown={preventDefault}
            edge="end"
          >
            <Add />
          </IconButton>
        </InputAdornment>
      ),
    }),
    [t]
  );

  return (
    <article className="flex flex-col px-4 pt-6">
      <h3 className="text-lg font-bold mb-3">
        {t("discover-page_keywords-filter-title")}
      </h3>
      <div className="flex flex-col">
        <form
          className="w-full flex"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <TextField
            name="keyword"
            autoComplete="off"
            className="w-full"
            label={t("discover-page_keywords-label")}
            variant="outlined"
            inputRef={register({
              required: t("discover-page_keyword-required"),
            })}
            error={hasError("keyword")}
            helperText={getErrorMessage("keyword")}
            inputProps={inputProps}
            InputProps={keywordInputProps}
          />
        </form>
        <div className="flex flex-wrap mt-4">
          {activeKeywords.map((keyword) => (
            <KeywordChip key={keyword} label={keyword} onChange={onChange} />
          ))}
        </div>
      </div>
    </article>
  );
};

const KeywordChip = ({ label, onChange }) => {
  const onDelete = useCallback(() => {
    onChange((state) => state.filter((item) => item !== label));
  }, [label, onChange]);
  return <Chip className="m-1" label={label} onDelete={onDelete} />;
};

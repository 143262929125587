import { useCallback } from "react";
import Auth from "@aws-amplify/auth";
import { ConsoleLogger as Logger } from "@aws-amplify/core";
import { useAuthContext } from "./useAuthContext";
import { AuthState } from "../data/authState";

const logger = new Logger("useConfirmSignUp");

export const useConfirmSignUp = () => {
  const { handleStateChange, authData } = useAuthContext();

  const username = authData ? authData.username : "";

  const confirmSignUp = useCallback(
    async (code) => {
      try {
        await Auth.confirmSignUp(username, code);
        handleStateChange(AuthState.SIGN_IN, authData);
      } catch (error) {
        logger.error(error);
        throw error;
      }
    },
    [authData, handleStateChange, username]
  );

  const resendCode = useCallback(() => Auth.resendSignUp(username), [username]);

  return {
    username,
    confirmSignUp,
    resendCode,
  };
};

import React, { useCallback, useMemo } from "react";
import { FederatedSignIn } from "./FederatedSignIn";
import { useSignUp } from "./hooks/useSignUp";
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Link,
} from "@material-ui/core";
import { NavigationHeader } from "../components/NavigationHeader";
import { useForm } from "react-hook-form";
import { FormError } from "../components/FormError";
import { useTranslation, Trans } from "react-i18next";
import { useState } from "react";
import { ReactComponent as EyeIcon } from "../assets/icons/eye.svg";
import { useErrors } from "../hooks/useErrors";
import { useOnboarding } from "./hooks/useOnboarding";
import { ReactComponent as CloseIcon } from "../assets/icons/close.svg";
import { UserChoice } from "./UserChoice";

const preventDefault = (event) => {
  event.preventDefault();
};

const linkTermsOfService = (
  <Link
    className="font-bold"
    target="_blank"
    rel="noopener"
    href="https://google.com"
    color="inherit"
  />
);

const linkPrivacyPolicy = (
  <Link
    className="font-bold"
    target="_blank"
    rel="noopener"
    href="https://google.com"
    color="inherit"
  />
);
const linkDefaultNotificationSettings = (
  <Link
    className="font-bold"
    target="_blank"
    rel="noopener"
    href="https://google.com"
    color="inherit"
  />
);

const termsAndConditionsComponents = {
  linkTermsOfService,
  linkPrivacyPolicy,
  linkDefaultNotificationSettings,
};

export const SignUp = ({ validationData }) => {
  const { register, handleSubmit, errors } = useForm();

  const { hasError, getErrorMessage } = useErrors(errors);

  const { goToOnboarding } = useOnboarding();

  const { t } = useTranslation();

  const { signUp } = useSignUp();

  const [userType, setUserType] = useState(null);

  const [formError, setFormError] = useState(null);

  const [disabled, setDisabled] = useState(false);

  const onSubmit = useCallback(
    async ({ name, username, password }) => {
      try {
        setDisabled(true);
        await signUp(name, username, password, validationData, userType);
      } catch (error) {
        setFormError(error);
        setDisabled(false);
      }
    },
    [signUp, validationData, userType]
  );

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword((state) => !state);
  }, []);

  const handleUserTypeSelected = useCallback((selectedUserType) => {
    setUserType(selectedUserType);
  }, []);

  const passwordInputProps = useMemo(
    () => ({
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label={t("signup-page_img-show-password-alt")}
            onClick={handleClickShowPassword}
            onMouseDown={preventDefault}
            edge="end"
          >
            <EyeIcon />
          </IconButton>
        </InputAdornment>
      ),
    }),
    [handleClickShowPassword, t]
  );

  return (
    <article className="mx-auto flex flex-col items-center">
      <NavigationHeader
        title={t("signup-page_header")}
        onActionClick={goToOnboarding}
        icon={<CloseIcon />}
      />
      {userType ? (
        <>
          <form
            className="w-full flex flex-col items-center px-8"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <div className="mt-10 w-full">
              <TextField
                name="name"
                className="w-full"
                autoComplete="name"
                autoFocus={true}
                label={t("signup-page_name-label")}
                variant="outlined"
                inputRef={register({
                  required: t("signup-page_name-required"),
                })}
                error={hasError("name")}
                helperText={getErrorMessage("name")}
              />
            </div>
            <div className="mt-6 w-full">
              <TextField
                name="username"
                className="w-full"
                autoComplete="username"
                label={t("signup-page_email-label")}
                variant="outlined"
                inputRef={register({
                  required: t("signup-page_email-required"),
                })}
                error={hasError("username")}
                helperText={getErrorMessage("username")}
              />
            </div>
            <div className="my-6 w-full">
              <TextField
                name="password"
                type={showPassword ? "text" : "password"}
                className="w-full"
                autoComplete="new-password"
                label={t("signup-page_password-label")}
                variant="outlined"
                inputRef={register({
                  required: t("signup-page_password-required"),
                })}
                InputProps={passwordInputProps}
                error={hasError("password")}
                helperText={getErrorMessage("password")}
              />
            </div>
            <FormError error={formError} />
            <div className="mt-6 w-full">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth={true}
                disabled={disabled}
              >
                {t("signup-page_signup-button")}
              </Button>
            </div>
            <div className="mt-6 text-graphite">
              <Trans
                i18nKey="signup-page_terms-and-conditions"
                components={termsAndConditionsComponents}
              />
            </div>
          </form>
          <div className="relative flex items-center justify-center my-12 w-full h-4">
            <div className="absolute border-t border-ash inset-0 mt-2" />
            <div className="px-4 text-black bg-white z-10">
              {t("signup-page_or-divider")}
            </div>
          </div>
          <div className="px-8 w-full">
            <div className="mb-6">
              <FederatedSignIn
                provider="Google"
                text={t("signup-page_google-login-button")}
              />
            </div>
            <div className="mb-6">
              <FederatedSignIn
                provider="Facebook"
                text={t("signup-page_facebook-login-button")}
              />
            </div>
          </div>
        </>
      ) : (
        <UserChoice onUserTypeSelected={handleUserTypeSelected} />
      )}
    </article>
  );
};

import React from "react";
import { Auth } from "aws-amplify";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import { ReactComponent as DiscoverIcon } from "../assets/icons/discover.svg";
import { ReactComponent as MessagesIcon } from "../assets/icons/messages.svg";
import { ReactComponent as FavsIcon } from "../assets/icons/favs.svg";
import { ReactComponent as ProfileIcon } from "../assets/icons/profile.svg";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const items = [
  {
    path: () => "/discover",
    label: "bottom-navigation_discover",
    icon: <DiscoverIcon />,
  },
  {
    path: () => "/messages",
    label: "bottom-navigation_messages",
    icon: <MessagesIcon />,
  },
  {
    path: () => "/favs",
    label: "bottom-navigation_favorites",
    icon: <FavsIcon />,
  },
  {
    path: (user) =>
      `/profile/${user.attributes["custom:userType"]}/${user.attributes.sub}`,
    label: "bottom-navigation_profile",
    icon: <ProfileIcon />,
  },
];

export const BottomNavigationBar = () => {
  const [value, setValue] = React.useState(0);

  const history = useHistory();

  const { t } = useTranslation();

  const onChange = useCallback(
    (_, index) => {
      const pathname = items[index].path(Auth.user);
      if (history.location.pathname !== pathname) {
        setValue(index);
        history.push(pathname);
      }
    },
    [history]
  );

  return (
    <BottomNavigation
      value={value}
      onChange={onChange}
      showLabels
      className="h-20 border-t border-black"
    >
      {items.map((item, index) => (
        <BottomNavigationAction
          key={index}
          label={t(item.label)}
          icon={item.icon}
        />
      ))}
    </BottomNavigation>
  );
};

import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Divider } from "@material-ui/core";
import { CoverPhoto, ProfilePhoto } from "../../../components";
import { SendMessage } from "../../../components/SendMessage";
import { ReactComponent as PlayingCards } from "../../../assets/icons/playing-cards.svg";
import { UserType } from "../../../models";
import { uploadPersonCoverImage, uploadPersonProfileImage } from "../../../api";

export const PersonProfile = ({ user, isOwner }) => {
  const { t } = useTranslation();
  const [person, setPerson] = useState(user);

  const handleCoverUpload = useCallback(
    async (file) => {
      const response = await uploadPersonCoverImage(person.id, file);
      setPerson(response);
    },
    [person]
  );

  const handleProfilePhotoUpload = useCallback(
    async (file) => {
      const response = await uploadPersonProfileImage(person.id, file);
      setPerson(response);
    },
    [person]
  );

  return (
    <article>
      <div className="mb-12 relative">
        <CoverPhoto
          isOwner
          imgKey={person.coverPic?.key}
          onImageUpload={handleCoverUpload}
        />
        <div className="absolute inset-x-0 top-20 flex justify-center">
          <ProfilePhoto
            imgKey={person.profilePic?.key}
            isOwner
            onImageUpload={handleProfilePhotoUpload}
          />
        </div>
        {!isOwner && (
          <div className="absolute right-5 top-36">
            <SendMessage />
          </div>
        )}
        <div className="mt-12 mx-3">
          <h1 className="text-lg font-bold text-center">{person.fullName}</h1>
          <h2 className="font-light text-center">{person.location}</h2>
          {isOwner && (
            <div className="text-center">
              <Link
                className="font-bold x text-wine lowercase"
                to={`/edit-profile/${UserType.Person}/${person.id}`}
              >
                {t("profile-page_edit-profile")}
              </Link>
            </div>
          )}
          <p className="font-normal mt-8">{person.biography}</p>
        </div>
      </div>
      <Divider />
      <div className="mt-8 mx-3">
        <PlayingCards className="mx-auto" />
        <h1 className="text-lg font-bold text-center">
          {t("profile-page_my-next-tat")}
        </h1>
        <p className="font-normal mt-8">{person.myNextTat}</p>
      </div>
    </article>
  );
};

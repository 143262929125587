import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  typography: {
    fontFamily: ["Ubuntu", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#780b0b",
    },
    secondary: {
      main: "#fff",
    },
    error: {
      main: "#ad0f0f",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: 700,
        "&:focus": {
          outline: "none",
        },
      },
      outlined: {
        padding: "10px 40px",
      },
      contained: {
        padding: "10px 40px",
        borderStyle: "solid",
        borderWidth: "1px",
      },
      containedPrimary: {
        borderColor: "#780b0b",
      },
      containedSecondary: {
        color: "#780b0b",
      },
    },
    MuiAlert: {
      standardError: {
        backgroundColor: "none",
      },
    },
    MuiToolbar: {
      root: {
        flexShrink: 0,
        height: "90px",
      },
    },
    MuiBottomNavigationAction: {
      root: {
        opacity: 0.5,
        "&$selected": {
          opacity: 1,
        },
        "&:focus": {
          outline: "none",
        },
      },
      label: {
        fontWeight: "bold",
        color: "black",
        textTransform: "uppercase",
        fontSize: "11px",
        "&$selected": {
          fontSize: "11px",
        },
      },
    },
    MuiAccordion: {
      root: {
        borderTop: "1px",
        borderTopColor: "#999",
        borderTopStyle: "solid",
        boxShadow: "none",
        "&:first-child": {
          borderTop: 0,
        },
        "&:not(:last-child)": {
          borderBottom: 0,
        },
        "&:before": {
          display: "none",
        },
        "&$expanded": {
          margin: "auto",
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        "&$expanded": {
          minHeight: "48px",
          margin: 0,
          backgroundColor: "#010202",
          color: "white",
        },
      },
      expandIcon: {
        "&$expanded": {
          color: "white",
        },
      },
      content: {
        "&$expanded": {
          margin: 0,
        },
      },
    },
    MuiTabs: {
      root: {
        minHeight: "32px",
      },
      indicator: {
        height: 0,
      },
    },
    MuiTab: {
      root: {
        minHeight: "32px",
        padding: 0,
        "&:focus": {
          outline: "none",
        },
      },
      textColorPrimary: {
        color: "black",
        "&$selected": {
          color: "white",
        },
      },
    },
  },
});

import React from "react";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import { withAuthenticator } from "./auth/Authenticator";
import { Layout } from "./components";
import { About, Discover, Profile, EditProfile, Search, Favs } from "./pages";
import "./styles/tailwind.generated.css";
import { RecoilRoot } from "recoil";

const Routes = () => (
  <Switch>
    <Route path="/profile/:type/:id">
      <Layout>
        <Profile />
      </Layout>
    </Route>
    <Route path="/edit-profile/:type/:id">
      <Layout>
        <EditProfile />
      </Layout>
    </Route>
    <Route path="/about">
      <Layout showBottomNavigation={false}>
        <About />
      </Layout>
    </Route>
    <Route path="/discover">
      <Layout>
        <Discover />
      </Layout>
    </Route>
    <Route path="/search">
      <Layout>
        <Search />
      </Layout>
    </Route>
    <Route path="/favs">
      <Layout>
        <Favs />
      </Layout>
    </Route>
    <Redirect to="/discover" />
  </Switch>
);

const AuthenticatedRoutes = withAuthenticator(Routes);

export const App = () => (
  <RecoilRoot>
    <HashRouter>
      <AuthenticatedRoutes />
    </HashRouter>
  </RecoilRoot>
);

import React, { useCallback, useMemo } from "react";
import { FederatedSignIn } from "./FederatedSignIn";
import { useSignIn } from "./hooks/useSignIn";
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Link,
} from "@material-ui/core";
import { NavigationHeader } from "../components/NavigationHeader";
import { useForm } from "react-hook-form";
import { FormError } from "../components/FormError";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ReactComponent as EyeIcon } from "../assets/icons/eye.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/close.svg";
import { useErrors } from "../hooks/useErrors";
import { useForgotPassword } from "./hooks/useForgotPassword";
import { useOnboarding } from "./hooks/useOnboarding";

const preventDefault = (event) => {
  event.preventDefault();
};

export const SignIn = ({ validationData }) => {
  const { register, handleSubmit, errors } = useForm();

  const { hasError, getErrorMessage } = useErrors(errors);

  const { goToOnboarding } = useOnboarding();

  const { t } = useTranslation();

  const { signIn, prefilledUsername } = useSignIn();

  const [formError, setFormError] = useState(null);

  const [disabled, setDisabled] = useState(false);

  const onSubmit = useCallback(
    async ({ username, password }) => {
      try {
        setDisabled(true);
        await signIn(username, password, validationData);
      } catch (error) {
        setFormError(error);
        setDisabled(false);
      }
    },
    [signIn, validationData]
  );

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword((state) => !state);
  }, []);

  const passwordInputProps = useMemo(
    () => ({
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label={t("signin-page_img-show-password-alt")}
            onClick={handleClickShowPassword}
            onMouseDown={preventDefault}
            edge="end"
          >
            <EyeIcon />
          </IconButton>
        </InputAdornment>
      ),
    }),
    [handleClickShowPassword, t]
  );

  const { goToForgotPassword } = useForgotPassword();

  const onForgotPassword = useCallback(
    (e) => {
      e.preventDefault();
      goToForgotPassword();
    },
    [goToForgotPassword]
  );

  return (
    <article className="mx-auto flex flex-col items-center">
      <NavigationHeader
        title={t("signin-page_header")}
        onActionClick={goToOnboarding}
        icon={<CloseIcon />}
      />
      <form
        className="w-full flex flex-col items-center px-8"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="mt-10 w-full">
          <TextField
            name="username"
            className="w-full"
            autoComplete="username"
            autoFocus={!prefilledUsername}
            label={t("signin-page_email-label")}
            variant="outlined"
            inputRef={register({
              required: t("signin-page_email-required"),
            })}
            error={hasError("username")}
            helperText={getErrorMessage("username")}
            defaultValue={prefilledUsername}
          />
        </div>
        <div className="my-6 w-full">
          <TextField
            name="password"
            type={showPassword ? "text" : "password"}
            className="w-full"
            autoComplete="current-password"
            autoFocus={!!prefilledUsername}
            label={t("signin-page_password-label")}
            variant="outlined"
            inputRef={register({
              required: t("signin-page_password-required"),
            })}
            InputProps={passwordInputProps}
            error={hasError("password")}
            helperText={getErrorMessage("password")}
          />
          <div className="flex justify-end mt-2">
            <Link
              className="font-bold"
              href="#"
              onClick={onForgotPassword}
              color="primary"
            >
              {t("signin-page_forgot-password")}
            </Link>
          </div>
        </div>
        <FormError error={formError} />
        <div className="mt-6 w-full">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth={true}
            disabled={disabled}
          >
            {t("signin-page_login-button")}
          </Button>
        </div>
      </form>
      <div className="relative flex items-center justify-center my-12 w-full h-4">
        <div className="absolute border-t border-ash inset-0 mt-2" />
        <div className="px-4 text-black bg-white z-10">
          {t("signin-page_or-divider")}
        </div>
      </div>
      <div className="px-8 w-full">
        <div className="mb-6">
          <FederatedSignIn
            provider="Google"
            text={t("signin-page_google-login-button")}
          />
        </div>
        <div className="mb-6">
          <FederatedSignIn
            provider="Facebook"
            text={t("signin-page_facebook-login-button")}
          />
        </div>
      </div>
    </article>
  );
};

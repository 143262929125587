import { createContext, useContext } from "react";

export const createNamedContext = (name, defaultValue) => {
  const context = createContext(defaultValue);
  context.displayName = name;

  return context;
};

export const AuthContext = createNamedContext("Auth", null);

export const useAuthContext = () => useContext(AuthContext);

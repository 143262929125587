import React, { Fragment, useState } from "react";
import { S3Image } from "../S3Image";
import ReactModal from "react-modal";
import { useCallback } from "react";
import { PostModal } from "./PostModal";

export const PostPreview = ({ id, alt, imgKey, intersectionOptions }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = useCallback(() => setIsOpen(true), []);

  const onClose = useCallback(() => setIsOpen(false), []);

  return (
    <Fragment>
      <S3Image
        onClick={onOpen}
        alt={alt}
        imgKey={imgKey}
        intersectionOptions={intersectionOptions}
        ImageProps={{
          className: "w-full",
        }}
      />
      <ReactModal
        className="relative bg-white pt-2 px-2 pb-4 w-full max-w-screen-sm shadow-sm mt-10 mx-auto outline-none"
        isOpen={isOpen}
        overlayClassName="fixed block z-10 p-4 inset-0 bg-black bg-opacity-25 overflow-auto"
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={true}
      >
        <PostModal id={id} />
      </ReactModal>
    </Fragment>
  );
};

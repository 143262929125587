import React, { useMemo, useCallback } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { TextField, IconButton, InputAdornment } from "@material-ui/core";
import { ReactComponent as SearchIcon } from "../assets/icons/search-small.svg";

export const SearchBar = ({ onChange, InputProps, searchValue = "" }) => {
  const { t } = useTranslation();
  const searchInputProps = useMemo(
    () => ({
      ...InputProps,
      startAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label={t("discover-page_img-location-alt")}
            edge="start"
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      ),
      className: clsx("rounded-lg h-9 bg-white", InputProps?.className),
    }),
    [t, InputProps]
  );

  const handleOnChange = useCallback(
    (event) => {
      const value = event.target.value;
      onChange(value);
    },
    [onChange]
  );
  return (
    <div className="w-full px-4 py-2 bg-wine">
      <TextField
        className="w-full"
        name="search"
        type="text"
        autoComplete="off"
        variant="outlined"
        onChange={handleOnChange}
        InputProps={searchInputProps}
        value={searchValue}
      />
    </div>
  );
};

import { useLayoutEffect } from "react";

export const useDarkTheme = () => {
  useLayoutEffect(() => {
    document.body.style.backgroundColor = "#000";

    return () => {
      document.body.style.backgroundColor = "#fff";
    };
  });
};

export const updateArtist = /* GraphQL */ `
  mutation UpdateArtist(
    $input: UpdateArtistInput!
    $condition: ModelArtistConditionInput
  ) {
    updateArtist(input: $input, condition: $condition) {
      id
      profilePic {
        key
      }
      fullName
      location
      biography
      hourlyRate
      pricePoint
      specializations
      studio {
        id
        profilePic {
          key
        }
        fullName
        location
        pricePoint
      }
      collaborationRequests {
        items {
          id
          ownerID
          artistID
          studioID
          studio {
            id
            profilePic {
              key
            }
            fullName
            location
            pricePoint
          }
        }
        nextToken
      }
    }
  }
`;

export const updateStudio = /* GraphQL */ `
  mutation UpdateStudio(
    $input: UpdateStudioInput!
    $condition: ModelStudioConditionInput
  ) {
    updateStudio(input: $input, condition: $condition) {
      id
      fullName
      location
      biography
      profilePic {
        key
      }
      coverPic {
        key
      }
      artists {
        items {
          id
          profilePic {
            key
          }
          fullName
          location
          pricePoint
        }
      }
      hourlyRate
      pricePoint
      specializations
      likedByViewer
      collaborationRequests {
        items {
          id
          ownerID
          artistID
          studioID
          artist {
            id
            profilePic {
              key
            }
            fullName
            location
            pricePoint
          }
        }
        nextToken
      }
    }
  }
`;

import React, { useCallback } from "react";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { UserType } from "../models/UserType";
import { ReactComponent as ParlorIcon } from "../assets/icons/parlor.svg";
import { ReactComponent as LoverIcon } from "../assets/icons/lover.svg";

export const UserChoice = ({ onUserTypeSelected }) => {
  const { t } = useTranslation();

  const selectUserType = useCallback(
    async (userType) => {
      onUserTypeSelected(userType);
    },
    [onUserTypeSelected]
  );

  return (
    <>
      <div className="w-full flex flex-col items-center px-8 mt-12">
        <LoverIcon />
        <Button
          className="mt-10"
          variant="contained"
          color="primary"
          onClick={() => selectUserType(UserType.Person)}
        >
          {t("signup-page_lover-button")}
        </Button>
      </div>
      <div className="relative flex items-center justify-center my-12 w-full h-4">
        <div className="absolute border-t border-ash inset-0 mt-2" />
        <div className="px-4 text-black bg-white z-10">
          {t("signup-page_or-divider")}
        </div>
      </div>

      <div className="w-full flex flex-col items-center px-8 mt-3 mb-12">
        <ParlorIcon />
        <div className="flex flex-col justify-around">
          <Button
            className="mt-10"
            variant="contained"
            color="primary"
            onClick={() => selectUserType(UserType.Artist)}
          >
            {t("signup-page_artist-button")}
          </Button>
          <Button
            className="mt-10"
            variant="contained"
            color="primary"
            onClick={() => selectUserType(UserType.Studio)}
          >
            {t("signup-page_studio-button")}
          </Button>
        </div>
      </div>
    </>
  );
};

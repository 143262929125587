import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { List } from "@material-ui/core";
import { searchArtistsByName } from "../../api/ArtistApi";
import { searchStudiosByName } from "../../api/StudioApi";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import { Tabs, NavigationHeader, SearchBar } from "../../components";
import { SearchItem } from "./SearchItem";
import { UserType } from "../../models";

const tabs = [
  {
    label: "top-navigation_artists",
  },
  {
    label: "top-navigation_studios",
  },
];

export const Search = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [artists, setArtists] = useState([]);
  const [studios, setStudios] = useState([]);

  const fetchArtists = useCallback(async (value) => {
    const { artists: response } = await searchArtistsByName(value);
    setArtists(response);
  }, []);

  const fetchStudios = useCallback(async (value) => {
    const { studios: response } = await searchStudiosByName(value);
    setStudios(response);
  }, []);

  useEffect(() => {
    if (selectedTab === 0 && searchValue) {
      fetchArtists(searchValue);
    }
    if (selectedTab === 1 && searchValue) {
      fetchStudios(searchValue);
    }
  }, [fetchArtists, fetchStudios, searchValue, selectedTab]);

  const type = selectedTab === 0 ? UserType.Artist : UserType.Studio;

  const items = selectedTab === 0 ? artists : studios;

  return (
    <article className="flex flex-col h-screen">
      <NavigationHeader
        title={t("search-page_header")}
        icon={<ArrowLeft />}
        onActionClick={history.goBack}
      />
      <SearchBar
        placeholder={t("search-page_artist_input_placeholder")}
        searchValue={searchValue}
        onChange={setSearchValue}
      />
      <Tabs
        tabs={tabs}
        selectedTab={selectedTab}
        onSelectedTab={setSelectedTab}
      />
      <List disablePadding={true}>
        {items.map((item) => (
          <SearchItem item={item} type={type} key={item.id} />
        ))}
      </List>
    </article>
  );
};

import React, { useMemo, useCallback } from "react";
import { ProfilePhoto } from "../../../components/ProfilePhoto";
import { useTranslation } from "react-i18next";
import { Specialization } from "../../../models/Specialization";
import { PostPreview } from "../../../components/PostPreview";
import { LikedToggle } from "../../../components/LikedToggle";
import { priceMapping } from "../../../models/Price";
import { useRecoilValue } from "recoil";
import { artistsListState } from "./artistsListState";
import { UserType } from "../../../models";
import { useHistory } from "react-router-dom";

export const ArtistCard = ({ index, style, liked = false }) => {
  const { t } = useTranslation();

  const history = useHistory();

  const intersectionOptions = useMemo(
    () => ({
      root: document.querySelector("#layout-scroll"),
      rootMargin: "240px 60px", // prefetch artist card on scroll
    }),
    []
  );

  const artists = useRecoilValue(artistsListState);

  const artist = artists[index];

  const specializations = useMemo(() => {
    const maxNumberOfSpecializations = 3;
    return artist
      ? artist.specializations.slice(0, maxNumberOfSpecializations)
      : [];
  }, [artist]);

  const posts = useMemo(() => {
    const maxNumberOfPosts = 12;
    return artist ? artist.posts.items.slice(0, maxNumberOfPosts) : [];
  }, [artist]);

  const handleOnClick = useCallback(() => {
    history.push(`/profile/${UserType.Artist}/${artist?.id}`);
  }, [artist, history]);

  if (!artist) {
    return <article style={style}>Loading...</article>;
  }

  return (
    <article style={style}>
      <div className="w-full flex flex-col rounded-tl-xl rounded-bl-xl border-ash border-t-1/2 border-b-1/2 border-l-1/2 mb-4 py-3 px-2">
        <div className="absolute top-3 right-3">
          <LikedToggle liked={liked} />
        </div>
        <div className="flex" onClick={handleOnClick}>
          <ProfilePhoto
            imgKey={artist.profilePic?.key}
            size="md"
            intersectionOptions={intersectionOptions}
          />
          <div className="flex flex-col pl-3">
            <div className="font-bold">{artist.fullName}</div>
            <div>{artist.location}</div>
            <div className="font-bold">
              {artist.pricePoint && t(priceMapping[artist.pricePoint])}
            </div>
            <div className="flex my-4">
              {specializations.map((specialization) => (
                <div
                  className="border-1/2 border-black rounded-lg mr-2 px-2 py-1 text-xs"
                  key={specialization}
                >
                  {t(`${Specialization[specialization]}-label`)}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex overflow-auto">
          {posts.map((post) => (
            <div
              key={post.id}
              className="w-24 h-20 flex-shrink-0 mr-1 rounded-lg overflow-hidden"
            >
              <PostPreview
                id={post.id}
                alt={post.id}
                imgKey={post.image.key}
                intersectionOptions={intersectionOptions}
              />
            </div>
          ))}
        </div>
      </div>
    </article>
  );
};

import React, { useCallback } from "react";
import { Chip } from "@material-ui/core";
import { ReactComponent as XDark } from "../assets/icons/x-dark.svg";

export const SpecializationChip = ({ label, deleteable, onChange }) => {
  const onDelete = useCallback(() => {
    onChange((state) => state.filter((item) => item !== label));
  }, [label, onChange]);
  return (
    <Chip
      variant="outlined"
      className="mx-1 text-sm lowercase font-normal rounded-lg"
      deleteIcon={<XDark />}
      label={label}
      onDelete={deleteable && onDelete}
    />
  );
};

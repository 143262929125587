import React, { useCallback, useMemo } from "react";
import { S3Image } from "../../../components/S3Image";
import { ProfilePhoto } from "../../../components/ProfilePhoto";
import { LikedToggle } from "../../../components/LikedToggle";
import { UserType } from "../../../models/UserType";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { studiosListState } from "./studiosListState";

export const StudioCard = ({ index, style, liked = false }) => {
  const intersectionOptions = useMemo(
    () => ({
      root: document.querySelector("#layout-scroll"),
      rootMargin: "340px 0px", // prefetch studio card on scroll
    }),
    []
  );

  const history = useHistory();

  const studios = useRecoilValue(studiosListState);

  const studio = studios[index];

  const handleStudioSelected = useCallback(() => {
    if (studio && studio.id) {
      history.push(`/profile/${UserType.Studio}/${studio.id}`);
    }
  }, [studio, history]);

  if (!studio) {
    return <article style={style}>Loading...</article>;
  }

  return (
    <article style={style}>
      <div
        className="relative w-full rounded-xl overflow-hidden border-ash border-1/2"
        onClick={handleStudioSelected}
      >
        <div className="absolute bottom-3 right-3">
          <LikedToggle liked={liked} />
        </div>
        <div className="h-24 overflow-hidden">
          <S3Image
            alt={studio.id}
            imgKey={studio.coverPic?.key}
            ImageProps={{
              className: "w-full h-24 object-cover",
            }}
            intersectionOptions={intersectionOptions}
          />
        </div>
        <div className="absolute inset-x-0 top-16 flex justify-center">
          <ProfilePhoto
            alt={studio.fullName}
            imgKey={studio.profilePic?.key}
            size="md"
            intersectionOptions={intersectionOptions}
          />
        </div>
        <div className="py-4">
          <div className="font-bold text-center">{studio.fullName}</div>
          <div className="text-center">{studio.location}</div>
        </div>
      </div>
    </article>
  );
};

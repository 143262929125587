import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { UserType, ConnectionStatus } from "../../../models";
import { SearchBar, ConnectionCard } from "../../../components";

export const ArtistConnectionsItem = ({
  studio,
  artists = [],
  onSearch,
  onConnect,
  onSendMessage,
  onRemoveConnection,
  onRemoveRequest,
  onConfirmRequest,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    onSearch(searchValue);
  }, [onSearch, searchValue]);

  const handleItemSelected = useCallback(
    (id) => {
      history.push(`/profile/${UserType.Artist}/${id}`);
    },
    [history]
  );

  const getArtistVariant = useCallback(
    (artist) => {
      return studio.collaborationRequests.items.some(
        (request) => request.artistID === artist.id
      )
        ? ConnectionStatus.Pending
        : ConnectionStatus.Potential;
    },
    [studio]
  );

  const handleConnect = useCallback(
    async (requesteeId) => {
      if (onConnect) {
        await onConnect(requesteeId);
        setSearchValue("");
      }
    },
    [onConnect]
  );

  return (
    <div className="w-full flex flex-col">
      <SearchBar
        placeholder={t("search-page_artist_input_placeholder")}
        searchValue={searchValue}
        onChange={setSearchValue}
      />
      <div className="my-3 mx-4">
        {searchValue && artists.length > 0 ? (
          <>
            {artists.map((artist) => (
              <ConnectionCard
                key={`cc-${artist.id}`}
                item={artist}
                artistId={artist.id}
                className="my-6"
                variant={getArtistVariant(artist)}
                onClick={() => handleItemSelected(artist.id)}
                onConnect={handleConnect}
              />
            ))}
          </>
        ) : (
          <>
            {studio?.artists?.items.map((studioArtist) => (
              <ConnectionCard
                key={`sa-${studioArtist.id}`}
                item={studioArtist}
                artistId={studioArtist.id}
                className="my-6"
                variant={ConnectionStatus.Connected}
                onRemoveConnection={onRemoveConnection}
                onSendMessage={onSendMessage}
                onClick={() => handleItemSelected(studioArtist.id)}
              />
            ))}
            <>
              {studio?.collaborationRequests?.items.map((request) => (
                <ConnectionCard
                  key={`cc-${request.id}`}
                  item={request.artist}
                  artistId={request.artist.id}
                  requestId={request.id}
                  isOwner={request.ownerID === studio.id}
                  className="my-6"
                  variant={ConnectionStatus.Pending}
                  onConfirmRequest={onConfirmRequest}
                  onRemoveRequest={onRemoveRequest}
                  onClick={() => handleItemSelected(request.artist.id)}
                />
              ))}
            </>
          </>
        )}
      </div>
    </div>
  );
};

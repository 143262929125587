import React, { useCallback, useMemo } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import specializationImage from "../../assets/images/specializations/general.png";
import { Specialization } from "../../models";

export const SpecializationsFilter = ({ activeSpecializations, onChange }) => {
  const { t } = useTranslation();
  return (
    <article className="flex flex-col pl-4 pt-6">
      <h3 className="text-lg font-bold mb-3">
        {t("discover-page_specialization-filter-title")}
      </h3>
      <div className="flex overflow-auto pr-4">
        {Object.keys(Specialization).map((key, index) => (
          <SpecializationItem
            key={index}
            specialization={key}
            label={t(`${Specialization[key]}-label`)}
            isActive={activeSpecializations.indexOf(key) > -1}
            onChange={onChange}
          />
        ))}
      </div>
    </article>
  );
};

const style = { width: "100px", height: "140px", marginRight: "5px" };

const SpecializationItem = ({ specialization, label, isActive, onChange }) => {
  const imageClassName = clsx({
    "opacity-25": !isActive,
    "opacity-100": isActive,
  });

  const imageStyle = useMemo(() => {
    if (isActive) {
      return {};
    }
    return {
      filter: "grayscale(100%)",
    };
  }, [isActive]);

  const onClick = useCallback(() => {
    if (isActive) {
      onChange((state) => state.filter((item) => item !== specialization));
    } else {
      onChange((state) => [...state, specialization]);
    }
  }, [isActive, onChange, specialization]);
  return (
    <div
      className="rounded-lg relative flex-shrink-0 overflow-hidden"
      style={style}
      onClick={onClick}
    >
      <img
        className={imageClassName}
        alt={label}
        src={specializationImage}
        style={imageStyle}
      />
      <p className="absolute right-0 bottom-0 left-0 bg-black text-xs text-white break-all py-1 px-2">
        {label}
      </p>
    </div>
  );
};

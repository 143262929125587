export const cities = [
  "Oakland, California",
  "Austin, Texas",
  "Chicago, Illinois",
  "Denver, Colorado",
  "Honolulu, Hawaii",
  "Houston, Texas",
  "Los Angeles, California",
  "Las Vegas, Nevada",
  "Miami, Florida",
  "San Francisco, California",
  "New York City, New York",
  "San Diego, California",
  "Portland, Oregon",
];

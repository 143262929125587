import React from "react";
import { AuthRoute } from "./AuthRoute";
import { AuthRouter } from "./AuthRouter";
import { AuthState } from "./data/authState";

export const Authenticator = ({ children, ...authConfig }) => (
  <AuthRouter {...authConfig}>{children}</AuthRouter>
);

const validAuthStates = [AuthState.SIGNED_IN];

export const withAuthenticator = (Component, authenticatorProps = {}) => (
  props
) => (
  <Authenticator {...authenticatorProps}>
    <AuthRoute validAuthStates={validAuthStates}>
      {(authConfigProps) => <Component {...authConfigProps} {...props} />}
    </AuthRoute>
  </Authenticator>
);

import API, { graphqlOperation } from "@aws-amplify/api";
import { updateArtist } from "../graphql/custom-mutations";
import awsExports from "../aws-exports";
import {
  searchArtists as searchArtistsGraphQL,
  getArtistProfile as getArtistProfileGraphQL,
  getArtistEditProfile as getArtistEditProfileGraphQL,
} from "../graphql/custom-queries";
import { uploadFile } from "./StorageApi";
import { pickBy, identity } from "lodash";
import { graphqlAndFilter, graphqlOrFilter } from "./utils";

const errorHandler = (e) => alert("Something went terribly wrong:", e);

export const searchArtists = async (
  location = null,
  specializations = [],
  price = null,
  nextToken = null,
  limit = 20
) => {
  const filter = graphqlAndFilter(
    location && { location: { eq: location } },
    graphqlOrFilter(
      ...specializations.map((specialization) => ({
        specializations: { eq: specialization },
      }))
    )
    // TODO Revert price when more data is available
    // price && { pricePoint: { eq: price } }
  );

  const query = pickBy(
    {
      limit,
      filter,
      nextToken,
    },
    identity
  );
  try {
    const { data } = await API.graphql(
      graphqlOperation(searchArtistsGraphQL, query)
    );
    return data.searchArtists;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

export const searchArtistsByName = async (searchValue, limit = 20) => {
  const filters = { or: [] };
  if (searchValue) {
    filters.or.push({ owner: { matchPhrasePrefix: searchValue } });
    filters.or.push({ fullName: { matchPhrasePrefix: searchValue } });
  }
  const query = filters.or.length > 0 ? { filter: filters, limit } : { limit };
  try {
    const { data } = await API.graphql(
      graphqlOperation(searchArtistsGraphQL, query)
    );
    const { items: artists, nextToken } = data.searchArtists;
    return { artists, nextToken };
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

export const getArtistProfile = async (id) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(getArtistProfileGraphQL, { id })
    );
    return data.getArtist;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

export const getArtistEditProfile = async (id) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(getArtistEditProfileGraphQL, { id })
    );
    return data.getArtist;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

export const updateArtistById = async (input) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(updateArtist, { input })
    );
    return data.updateArtist;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

export const uploadArtistProfileImage = async (id, file) => {
  const visibility = "public";
  try {
    const key = await uploadFile(file.key, file.localUri, visibility);
    const { data } = await API.graphql(
      graphqlOperation(updateArtist, {
        input: {
          id,
          profilePic: {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key,
            visibility,
          },
        },
      })
    );
    return data.updateArtist;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

export const removeArtistStudio = async (id) => {
  const placeholder = "studio-placeholder-id";
  try {
    const { data } = await API.graphql(
      graphqlOperation(updateArtist, { input: { id, studioID: placeholder } })
    );
    return data.updateArtist;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

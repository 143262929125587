import React from "react";
import { Button } from "@material-ui/core";
import { useOnboarding } from "./hooks/useOnboarding";
import { CarouselProvider, Slider, Slide, DotGroup } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import "../styles/pure-react-carousel.css";
import splash1 from "../assets/images/splash_1.png";
import splash2 from "../assets/images/splash_2.png";
import splash3 from "../assets/images/splash_3.png";
import splash4 from "../assets/images/splash_4.png";
import { useForm } from "react-hook-form";
import { ReactComponent as Logo } from "../assets/images/tattd_logo.svg";
import { useTranslation } from "react-i18next";
import { useDarkTheme } from "../hooks";

export const Onboarding = () => {
  useDarkTheme();

  const { goToSignIn, goToSignUp } = useOnboarding();

  const { handleSubmit } = useForm();

  const { t } = useTranslation();

  return (
    <article className="mx-auto flex flex-col items-center relative">
      <CarouselProvider
        naturalSlideWidth={1}
        naturalSlideHeight={1}
        totalSlides={4}
      >
        <Slider className="h-screen">
          <Slide index={0} className="h-screen relative">
            <div className="absolute inset-0 flex flex-col items-center">
              <Logo className="mt-auto mb-12 mx-auto" />
              <p className="mb-56 text-white text-center max-w-xs">
                {t("onboarding-page_splash-1-tagline")}
              </p>
            </div>
            <img
              className="w-full"
              src={splash1}
              alt={t("onboarding-page_img-splash-1-alt")}
            />
          </Slide>
          <Slide index={1} className="h-screen relative">
            <div className="absolute inset-0 flex flex-col pb-48 items-center">
              <h2 className="my-auto text-4xl font-bold text-white text-center max-w-xs uppercase">
                {t("onboarding-page_splash-2-highlight")}
              </h2>
              <p className="mb-auto text-white text-center max-w-xs">
                {t("onboarding-page_splash-2-tagline")}
              </p>
            </div>
            <img
              className="w-full"
              src={splash2}
              alt={t("onboarding-page_img-splash-2-alt")}
            />
          </Slide>
          <Slide index={2} className="h-screen relative">
            <div className="absolute inset-0 flex flex-col  pb-48 items-center">
              <h2 className="my-auto text-4xl font-bold text-white text-center max-w-xs uppercase">
                {t("onboarding-page_splash-3-highlight")}
              </h2>
              <p className="mb-auto text-white text-center max-w-xs">
                {t("onboarding-page_splash-3-tagline")}
              </p>
            </div>
            <img
              className="w-full"
              src={splash3}
              alt={t("onboarding-page_img-splash-3-alt")}
            />
          </Slide>
          <Slide index={3} className="h-screen relative">
            <div className="absolute inset-0 flex flex-col  pb-48 items-center">
              <h2 className="my-auto text-4xl font-bold text-white text-center max-w-xs uppercase">
                {t("onboarding-page_splash-4-highlight")}
              </h2>
              <p className="mb-auto text-white text-center max-w-xs">
                {t("onboarding-page_splash-4-tagline")}
              </p>
            </div>
            <img
              className="w-full"
              src={splash4}
              alt={t("onboarding-page_img-splash-4-alt")}
            />
          </Slide>
        </Slider>
        <DotGroup />
      </CarouselProvider>
      <form
        className="w-full flex flex-col items-center px-8 absolute bottom-0 mb-32"
        onSubmit={handleSubmit(goToSignIn)}
        noValidate
        autoComplete="off"
      >
        <div className="flex justify-around w-full">
          <Button
            className="focus:outline-none"
            variant="contained"
            type="submit"
            color="secondary"
          >
            {t("onboarding-page_login-button")}
          </Button>
          <Button
            className="focus:outline-none"
            variant="contained"
            type="button"
            color="primary"
            onClick={goToSignUp}
          >
            {t("onboarding-page_signup-button")}
          </Button>
        </div>
      </form>
    </article>
  );
};

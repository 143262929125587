import React from "react";
import { PostItem } from "./PostItem";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import { InitialFeed } from "../InitialFeed";
import { LoadingSpinner } from "../LoadingSpinner";
import { NoResultsFound } from "../NoResultsFound";

const numberOfColumns = 3;

const renderInk = ({ index, style }) => {
  const leadingIndex = index * numberOfColumns;
  console.log("renderink");
  return (
    <article style={style} className="flex">
      <PostItem index={leadingIndex} />
      <PostItem index={leadingIndex + 1} />
      <PostItem index={leadingIndex + 2} />
    </article>
  );
};

export const PostsList = ({
  hasMore,
  itemsLength,
  fetchItems,
  isLoaded,
  isLoading,
}) => {
  const roundedItemsLength = Math.round(itemsLength / numberOfColumns);

  const isItemLoaded = (index) => !hasMore || index < roundedItemsLength;

  const itemCount = hasMore ? roundedItemsLength + 1 : roundedItemsLength;

  if (!isLoaded && !isLoading) {
    return <InitialFeed textKey="discover-page_search-posts-tagline" />;
  }

  if (!isLoaded && isLoading) {
    return <LoadingSpinner textKey="discover-page_loading-posts-tagline" />;
  }

  if (isLoaded && itemsLength === 0) {
    return <NoResultsFound textKey="discover-page_no-results-posts-tagline" />;
  }

  console.log(roundedItemsLength);
  console.log(itemCount);

  return (
    <div className="flex flex-grow flex-col p-4">
      <AutoSizer>
        {({ width, height }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={fetchItems}
          >
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                height={height}
                width={width}
                itemCount={itemCount}
                itemSize={width / 3}
                onItemsRendered={onItemsRendered}
                ref={ref}
              >
                {renderInk}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </div>
  );
};

import { useCallback } from "react";

import { useAuthContext } from "./useAuthContext";
import { AuthState } from "../data/authState";

export const useOnboarding = () => {
  const { handleStateChange } = useAuthContext();

  const goToSignIn = useCallback(
    () => handleStateChange(AuthState.SIGN_IN, null),
    [handleStateChange]
  );
  const goToSignUp = useCallback(
    () => handleStateChange(AuthState.SIGN_UP, null),
    [handleStateChange]
  );
  const goToOnboarding = useCallback(() => {
    handleStateChange(AuthState.ONBOARDING, null);
  }, [handleStateChange]);

  return {
    goToSignIn,
    goToSignUp,
    goToOnboarding,
  };
};

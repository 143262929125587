import React, { useCallback, useState } from "react";
import { NavigationHeader } from "./../components";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "./../assets/icons/close.svg";
import { Button, TextField, Link } from "@material-ui/core";
import { FormError } from "./../components/FormError";
import { useErrors } from "./../hooks/useErrors";
import { useOnboarding } from "./hooks/useOnboarding";
import { useConfirmSignUp } from "./hooks/useConfirmSignUp";

export const ConfirmSignUp = () => {
  const { register, handleSubmit, errors } = useForm();

  const { hasError, getErrorMessage } = useErrors(errors);

  const { username, confirmSignUp, resendCode } = useConfirmSignUp();

  const { goToSignUp } = useOnboarding();

  const { t } = useTranslation();

  const [formError, setFormError] = useState(null);

  const [disabled, setDisabled] = useState(false);

  const onSubmit = useCallback(
    async ({ code }) => {
      try {
        setDisabled(true);
        await confirmSignUp(code);
      } catch (error) {
        setFormError(error);
        setDisabled(false);
      }
    },
    [confirmSignUp]
  );

  return (
    <article className="mx-auto flex flex-col items-center">
      <NavigationHeader
        title={t("confirm-signup-page_header")}
        onActionClick={goToSignUp}
        icon={<CloseIcon />}
      />
      <form
        className="w-full flex flex-col items-center px-8"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="mt-10 w-full">
          <TextField
            name="username"
            className="w-full"
            value={username}
            disabled={true}
            label={t("confirm-signup-page_email-label")}
            variant="outlined"
            error={hasError("username")}
            helperText={getErrorMessage("username")}
          />
        </div>
        <div className="my-6 w-full">
          <TextField
            name="code"
            className="w-full"
            autoFocus={true}
            label={t("confirm-signup-page_code-label")}
            variant="outlined"
            inputRef={register({
              required: t("confirm-signup-page_code-required"),
            })}
            error={hasError("code")}
            helperText={getErrorMessage("code")}
          />
          <div className="flex justify-end mt-2">
            <Link
              className="font-bold"
              href="#"
              onClick={resendCode}
              color="primary"
            >
              {t("confirm-signup-page_resend-code")}
            </Link>
          </div>
        </div>
        <FormError error={formError} />
        <div className="mt-6 w-full">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth={true}
            disabled={disabled}
          >
            {t("confirm-signup-page_submit-button")}
          </Button>
        </div>
      </form>
    </article>
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";

export const DeleteAccountItem = ({ onDelete }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex flex-col items-center">
      <div className="flex justify-center mt-5 w-full">
        <Button onClick={onDelete} variant="contained" color="primary">
          {t("edit-profile-page_delete-account")}
        </Button>
      </div>
    </div>
  );
};

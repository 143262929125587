import { atom } from "recoil";

export const artistsListState = atom({
  key: "artistsListState",
  default: [],
});

export const artistsNextTokenState = atom({
  key: "artistsNextTokenState",
  default: "",
});

export const artistsIsLoadingState = atom({
  key: "artistsIsLoadingState",
  default: false,
});

export const artistsIsLoadedState = atom({
  key: "artistsIsLoadedState",
  default: false,
});

export const artistsTotalState = atom({
  key: "artistsTotalState",
  default: 0,
});

import { useCallback } from "react";
import { getPostsByArtist } from "../api";
import { useEventCallback } from "rxjs-hooks";
import { tap, concatMap, take, flatMap, takeUntil } from "rxjs/operators";
import { from, Subject } from "rxjs";
import { useRecoilState } from "recoil";
import {
  postsListState,
  postsNextTokenState,
  postsIsLoadingState,
  postsIsLoadedState,
} from "../state";

export const useArtistPosts = (artistId) => {
  const [nextToken, setNextToken] = useRecoilState(postsNextTokenState);
  const [isLoading, setIsLoading] = useRecoilState(postsIsLoadingState);
  const [isLoaded, setIsLoaded] = useRecoilState(postsIsLoadedState);

  const [items, setItems] = useRecoilState(postsListState);

  const cancel$ = new Subject();

  const [fetchItems] = useEventCallback(
    (event$, _, input$) =>
      event$.pipe(
        concatMap(() =>
          input$.pipe(
            take(1),
            tap(() => setIsLoading(true)),
            flatMap(([artistId, nextToken]) =>
              from(getPostsByArtist(artistId, nextToken)).pipe(
                takeUntil(cancel$)
              )
            )
          )
        ),
        tap((response) => {
          setItems((state) => [...state, ...response.items]);
          setNextToken(response.nextToken);
          setIsLoading(false);
          setIsLoaded(true);
        })
      ),
    [],
    [artistId, nextToken]
  );

  const hasMore = nextToken !== null;

  const reload = useCallback(() => {
    cancel$.next();
    setIsLoaded(false);
    setIsLoading(false);
    setItems([]);
    setNextToken("");
    fetchItems();
  }, [cancel$, fetchItems, setIsLoaded, setIsLoading, setItems, setNextToken]);

  return {
    items,
    fetchItems,
    hasMore,
    isLoading,
    isLoaded,
    reload,
  };
};

import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { FormError, LocationFilter } from "../../../components";

export const LocationItem = ({ location: externalLocation = null, onSave }) => {
  const { t } = useTranslation();
  const [location, setLocation] = useState(externalLocation);
  const [formError, setFormError] = useState(null);

  const saveLocation = useCallback(async () => {
    try {
      onSave(location);
    } catch (error) {
      setFormError(error);
    }
  }, [onSave, location]);

  useEffect(() => {
    setLocation(externalLocation);
  }, [externalLocation]);

  return (
    <div className="w-full flex flex-col items-center px-8">
      <div className="mt-10 w-full">
        <LocationFilter size="medium" value={location} onChange={setLocation} />
      </div>
      <FormError error={formError} />
      <div className="flex justify-center mt-5 w-full">
        <Button onClick={saveLocation} variant="contained" color="primary">
          {t("edit-profile-page_save")}
        </Button>
      </div>
    </div>
  );
};

import React, { useCallback } from "react";
import { ReactComponent as ShareIcon } from "../assets/icons/share.svg";
import { IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const preventDefault = (event) => {
  event.preventDefault();
};

export const SocialShare = ({ liked = false }) => {
  const { t } = useTranslation();

  const onClick = useCallback(() => {
    // TODO share on social media
  }, []);

  return (
    <IconButton
      aria-label={t("action-button_social-share-item-alt")}
      onClick={onClick}
      onMouseDown={preventDefault}
    >
      <ShareIcon />
    </IconButton>
  );
};

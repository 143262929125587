import React, { useState, useMemo, useEffect } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { ErrorTranslation } from "../models";

export const FormError = ({ error }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const action = useMemo(
    () => (
      <IconButton
        aria-label="close"
        color="inherit"
        size="small"
        onClick={() => {
          setOpen(false);
        }}
      >
        <Close fontSize="inherit" />
      </IconButton>
    ),
    []
  );

  useEffect(() => {
    if (error) {
      setOpen(true);
    }
  }, [error]);

  if (!error) {
    return null;
  }

  const translatedMessage =
    error.message in ErrorTranslation && t(ErrorTranslation[error.message]);

  const message =
    translatedMessage || error.message || t("general_unknown-error");

  if (!open) {
    return null;
  }

  return (
    <Alert
      className="w-full"
      severity="error"
      action={action}
      variant="outlined"
    >
      <AlertTitle>Error</AlertTitle>
      {message}
    </Alert>
  );
};

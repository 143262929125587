/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:1ed6f9ec-07f7-4cc1-ba0a-21f09a5b2dbb",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_myltGvWTw",
    "aws_user_pools_web_client_id": "68ivnum5mpk432q08kan5a7dl0",
    "oauth": {
        "domain": "tattdda9abbff-da9abbff-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://dev.d20korx7tt3ihb.amplifyapp.com/",
        "redirectSignOut": "https://dev.d20korx7tt3ihb.amplifyapp.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "tattdf8294923028e48008b8ee268a701d1d9133524-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://7rzisazd2ze2xlmnnpzkq4gnn4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
